import React, { Fragment, useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import lib from "../config/lib";
import { FaRegEdit } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import Modal from 'react-bootstrap/Modal';
import Description from '../DescriptionModal';
import Loading from "../common/Loading";
import DeleteJob from "../DeleteButton";

function CompleteOrder() {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        sethandleOrderidsend(id);
    }

    useEffect(() => {
        handleSample();
    }, []);

    const handleSample = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.customorderstable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleSearch = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'order');
        formData.append('search', search);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.orderserch, formData);
            setData(response.data);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleFilter = async (e) => {
        var filter = e.target.value;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', filter);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.customorderstable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Modal

    const [jobno, setjobno] = useState('');
    const [maincat, setMaincat] = useState('');
    const [quality, setQuality] = useState('');
    const [sheetthickness, setSheetthickness] = useState('');
    const [boxstyle, setBoxstyle] = useState('');
    const [woodquality, setWoodQuality] = useState('');
    const [woodsize, setWoodSize] = useState('');
    const [punching, setPunching] = useState('');
    const [length, setLenth] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [tuckflap, setTuckflap] = useState('');
    const [glueflap, setGlueflap] = useState('');
    const [lock, setLock] = useState('');
    const [dustflap, setDustflap] = useState('');
    const [numberofups, setNumberofups] = useState('');
    const [sheetsize, setSheetsize] = useState('');
    const [rouletoroule, setRouletoroule] = useState('');
    const [rubbing, setRubbing] = useState('');
    const [coro, setCoro] = useState('');
    const [matrix, setMatrix] = useState('');
    const [stripping, setStripping] = useState('');
    const [pertinax, setPertinax] = useState('');
    const [embossing, setEmbossing] = useState('');
    const [description, setDescription] = useState('');
    const [fulde, setFulde] = useState('');
    const [fuldetype, setFuldetype] = useState('');
    const [thickness, setThickness] = useState('');
    const [rulequality, setRulequality] = useState('');
    const [diequility, setDiequility] = useState('');
    const [punchingthickness, setPunchingthickness] = useState('');
    const [machine, setMachine] = useState('');
    const [machinesize, setMachinesize] = useState('');
    const [material, setMaterial] = useState('');
    const [blisterstyle, setBlisterstyle] = useState('');
    const [cardsize, setCardsize] = useState('');
    const [width, setWidth] = useState('');
    const [bendingmargin, setBendingmargin] = useState('');
    const [numberguide, setNumberguide] = useState('');
    const [collersize, setCollersize] = useState('');
    const [numberdies, setNumberdies] = useState('');
    const [wood, setWood] = useState('');
    const [materialtype, setMaterialtype] = useState('');
    const [plactictype, setPlactictype] = useState('');
    const [rule, setRule] = useState('');
    const [ordertype, setOrdertype] = useState('');
    const [sid, setSid] = useState('');
    const [productimg, setProductImg] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');

    const [designerimg, setDesignerImg] = useState('');

    const [handleOrderidsend, sethandleOrderidsend] = useState('');

    const [shows, setShows] = useState(false);

    const [jobnumberdescription, setJobnumberdescription] = useState('');

    const handleCloses = () => setShows(false);
    const handleShows = (id) => {
        setShows(true);
        setJobnumberdescription(id);
    }


    useEffect(() => {
        handleQuationDisplay();
    }, []);

    const handleQuationDisplay = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.quationjobdisplay, formData);
            if (response.data.status === "success") {
                setjobno(response.data.jobno);
                setMaincat(response.data.setMaincat);
                setQuality(response.data.quality);
                setSheetthickness(response.data.sheetthickness);
                setBoxstyle(response.data.boxstyle);
                setWoodQuality(response.data.woodquality);
                setWoodSize(response.data.woodsize);
                setPunching(response.data.punching);
                setLenth(response.data.lenth);
                setWeight(response.data.weight);
                setHeight(response.data.height);
                setTuckflap(response.data.tuckflap);
                setGlueflap(response.data.glueflap);
                setLock(response.data.lock);
                setDustflap(response.data.dustflap);
                setNumberofups(response.data.numberofups);
                setSheetsize(response.data.sheetsize);
                setRouletoroule(response.data.rouletoroule);
                setRubbing(response.data.rubbing);
                setCoro(response.data.coro);
                setMatrix(response.data.matrix);
                setStripping(response.data.stripping);
                setPertinax(response.data.pertinax);
                setEmbossing(response.data.embossing);
                setDescription(response.data.description);
                setFulde(response.data.fulde);
                setFuldetype(response.data.fuldetype);
                setThickness(response.data.thickness);
                setRulequality(response.data.rulequality);
                setDiequility(response.data.diequility);
                setPunchingthickness(response.data.punchingthickness);
                setMachine(response.data.machine);
                setMachinesize(response.data.machinesize);
                setMaterial(response.data.material);
                setBlisterstyle(response.data.blisterstyle);
                setCardsize(response.data.cardsize);
                setWidth(response.data.width);
                setBendingmargin(response.data.bendingmargin);
                setNumberguide(response.data.numberguide);
                setCollersize(response.data.collersize);
                setNumberdies(response.data.numberdies);
                setWood(response.data.wood);
                setMaterialtype(response.data.materialtype);
                setPlactictype(response.data.plactictype);
                setRule(response.data.rule);
                setOrdertype(response.data.ordertype);
                setSid(response.data.sid);
                setProductImg(response.data.productimg);
                setStatus(response.data.tracker);
                setDate(response.data.date);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleDesignerimg = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        // formData.append('jobno', handleOrderidsend);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.designerimg, formData);
            if (response.data.status == "success") {
                setDesignerImg(response.data.designerimg);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }

    return (
        <Fragment>
            <div className="row align-items-center">
                <Loading loading={loading} />
                <div className="col-lg-3 col-md-10 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setSearch(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div className="col-lg-2 ms-lg-auto col-md-2">
                    <div className="me-2">
                        <Form.Select aria-label="Default select example" onChange={handleFilter}>
                            <option disabled>Select filter</option>
                            <option value="All">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Complete</option>
                        </Form.Select>
                    </div>
                </div>
            </div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Job Number</th>
                        <th>Job Name</th>
                        <th>Order  Type</th>
                        <th>Product Img</th>
                        <th>Customer Code</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData && currentPageData.map((item) => (
                        <tr>
                            <td>{item.orderid}</td>
                            <td>{item.jobname}</td>
                            <td>{item.ordertype}</td>
                            <td><a href={lib.imgurl + '/' + item.productimg} target="_blank" >View Image</a></td>
                            <td>{item.sid}</td>
                            <td>{item.date}</td>
                            <td>{item.status}</td>
                            <td className="d-flex">
                                <LuEye onClick={() => handleShow(item.orderid)} size={20} />
                                {/* <FaRegEdit onClick={() => handleShows(item.orderid)} size={20} className='ms-3' /> */}
                                <DeleteJob handleJobnumber={item.orderid} handleJobfrom={"order"}/>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Quotation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-12 d-flex justify-content-center mb-3' >
                            <span className=' invoce-tittle'>View Job Card</span>
                        </div>
                        {/* <div className='col-md-4'>
                            <span style={{ fontSize: 15 }}>Customer Image</span><span className='ms-2'><a href={lib.imgUrl + '/' + productimg} target="_blank" >View Image</a></span>
                        </div>

                        <div className='col-md-4'>
                            <span style={{ fontSize: 15 }}>Designer Image</span><span className='ms-2'><a href={lib.imgUrl + '/' + designerimg} target="_blank" >View Image</a></span>
                        </div> */}

                        <div className='col-md-6 mt-2'>
                            <label>Job no</label>
                            <input className='form-control' placeholder='Job no' value={jobno} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Customer code</label>
                            <input className='form-control' placeholder='customer code' value={sid} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Main Category</label>
                            <input className='form-control' placeholder='Category' value={maincat} />
                        </div>
                        <div className='col-md-6 mt-2'>
                            <label>Quality</label>
                            <input className='form-control' placeholder='Box style' value={quality} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Sheet thickness</label>
                            <input className='form-control' placeholder='Box style' value={thickness} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Box style</label>
                            <input className='form-control' placeholder='Box style' value={boxstyle} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Wood quality</label>
                            <input className='form-control' placeholder='Box style' value={woodquality} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Wood size</label>
                            <input className='form-control' placeholder='Box style' value={woodsize} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Punching</label>
                            <input className='form-control' placeholder='Box style' value={punching} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Length</label>
                            <input className='form-control' placeholder='Length' value={length} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Width</label>
                            <input className='form-control' placeholder='Width' value={width} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Height</label>
                            <input className='form-control' placeholder='Height' value={height} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Tuck flap</label>
                            <input className='form-control' placeholder='Sheet size' value={tuckflap} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Glue flap</label>
                            <input className='form-control' placeholder='Length' value={glueflap} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Lock</label>
                            <input className='form-control' placeholder='Width' value={lock} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Height</label>
                            <input className='form-control' placeholder='Height' value={height} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Dust flap</label>
                            <input className='form-control' placeholder='Date' value={dustflap} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Number of ups</label>
                            <input className='form-control' placeholder='Date' value={numberofups} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Sheet size</label>
                            <input className='form-control' placeholder='Date' value={sheetsize} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Rule to rule</label>
                            <input className='form-control' placeholder='Date' value={rouletoroule} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Rubbing</label>
                            <input className='form-control' placeholder='Date' value={rubbing} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Coro</label>
                            <input className='form-control' placeholder='Date' value={coro} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Matrix</label>
                            <input className='form-control' placeholder='Date' value={matrix} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Stripping</label>
                            <input className='form-control' placeholder='Date' value={stripping} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Pertinax</label>
                            <input className='form-control' placeholder='Date' value={pertinax} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Embossing</label>
                            <input className='form-control' placeholder='Date' value={embossing} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Description</label>
                            <input className='form-control' placeholder='Date' value={description} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Fulde</label>
                            <input className='form-control' placeholder='Date' value={fulde} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Fulde</label>
                            <input className='form-control' placeholder='Date' value={fulde} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Fulde type</label>
                            <input className='form-control' placeholder='Status' value={fuldetype} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Thickness</label>
                            <input className='form-control' placeholder='Status' value={thickness} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Rule quality	</label>
                            <input className='form-control' placeholder='Status' value={rulequality} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Die quility</label>
                            <input className='form-control' placeholder='Status' value={diequility} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Punching thickness</label>
                            <input className='form-control' placeholder='Status' value={punchingthickness} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Machine</label>
                            <input className='form-control' placeholder='Status' value={machine} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Machine size</label>
                            <input className='form-control' placeholder='Status' value={machinesize} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Material</label>
                            <input className='form-control' placeholder='Status' value={material} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Blister style</label>
                            <input className='form-control' placeholder='Status' value={blisterstyle} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Card size</label>
                            <input className='form-control' placeholder='Status' value={cardsize} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Width</label>
                            <input className='form-control' placeholder='Status' value={width} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Bending margin</label>
                            <input className='form-control' placeholder='Status' value={bendingmargin} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Number guide	</label>
                            <input className='form-control' placeholder='Status' value={numberguide} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Number guide	</label>
                            <input className='form-control' placeholder='Status' value={numberdies} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Coller size</label>
                            <input className='form-control' placeholder='Status' value={collersize} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Number dies</label>
                            <input className='form-control' placeholder='Status' value={numberdies} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Wood</label>
                            <input className='form-control' placeholder='Status' value={wood} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Material type</label>
                            <input className='form-control' placeholder='Status' value={materialtype} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Plactic type</label>
                            <input className='form-control' placeholder='Status' value={plactictype} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Rule</label>
                            <input className='form-control' placeholder='Status' value={rule} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Order type</label>
                            <input className='form-control' placeholder='Status' value={ordertype} />
                        </div>

                        {/* <div className='col-md-6 mt-2 d-flex align-items-center'>
                            <span style={{ fontSize: 15 }}>Priting Machine Data</span><span className='ms-2'><DuplexSelected handleSendsid={handleSendsid} /></span>
                        </div>
                        <div className='col-md-6 mt-2 d-flex align-items-center'>
                            <span style={{ fontSize: 15 }}>Die Machine Data</span><span className='ms-2'><DuplexSelect handleSendsid={handleSendsid} /></span>
                        </div> */}
                    </div>
                </Modal.Body>
                <div className='row'>
                    <div className='col-lg-2'>
                        <Button className='btn-primary' onClick={handleQuationDisplay}>Feth Data</Button>
                    </div>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Description */}

            <Modal show={shows} onHide={handleCloses} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Description jobnumberdescription={jobnumberdescription} handleJobfrom={'order'} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloses}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}
export default CompleteOrder