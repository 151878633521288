import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assect/css/custom.css';
import '../../assect/css/reset.css';
import '../../assect/css/layout.css';
import '../../assect/css/themes.css';
import '../../assect/css/typography.css';
import '../../assect/css/styles.css';
import '../../assect/css/shCore.css';
import '../../assect/css/bootstrap.css';
import '../../assect/css/jquery.jqplot.css';
import '../../assect/css/jquery-ui-1.8.18.custom.css';
import '../../assect/css/data-table.css';
import '../../assect/css/form.css';
import '../../assect/css/ui-elements.css';
import '../../assect/css/wizard.css';
import '../../assect/css/sprite.css';
import '../../assect/css/gradient.css';
import logo from '../../assect/images/logo.jpg';
import userphoto from '../../assect/images/user_thumb.png';
import { Link } from 'react-router-dom';

const AgentHeader = () =>{
    return(
        <div>
            <div id="container">
                <div className="row">
                    <div className="col">
                        <div id="header" className="blue_lin">
                            <div className="header_left">
                                <div className="logo">
                                    <img src={logo} width={140} height={61} alt="Ekra" />
                                </div>
                            </div>
                            <div className="mainicon">
                                <Link to="/agentdashboard" className="dashicon">Dashboard</Link>
                                <Link to="#" className="supporticon">Support</Link>
                                <Link href="#" className="supporticon">Logout</Link>
                            </div>

                            <div class="header_right">
                                <div id="user_nav">
                                    <ul>
                                        <li class="user_thumb"><a href="#"><span class="icon"><img src={userphoto} width={30} height={30} alt="User" /></span></a></li>
                                        <li class="user_info"><span class="user_name">Ritish Khanna</span><span>User</span></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AgentHeader;