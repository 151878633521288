import React, { Fragment, useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import lib from "../config/lib";
import { FaRegEdit } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { LuEye } from "react-icons/lu";
import Description from '../DescriptionModal';
import Loading from "../common/Loading";
import DeleteJob from "../DeleteButton";

function Consumable() {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        sethandleOrderidsend(id);
    }

    const [shows, setShows] = useState(false);

    const [jobnumberdescription, setJobnumberdescription] = useState('');

    const handleCloses = () => setShows(false);
    const handleShows = (id) => {
        setShows(true);
        setJobnumberdescription(id);
    }


    useEffect(() => {
        handleSample();
    }, []);

    const handleSample = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.consumabletable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleSearch = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'consumable');
        formData.append('search', search);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.orderserch, formData);
            setData(response.data);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleFilter = async (e) => {
        var filter = e.target.value;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', filter);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.consumabletable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Modal 

    const [productname, setproductname] = useState('');
    const [productprise, setproductprise] = useState('');
    const [productdescription, setproductdescription] = useState('');
    const [productimage, setproductImage] = useState('');
    const [customeraddress, setcustomeraddress] = useState('');
    const [date, setdate] = useState('');
    const [status, setstatus] = useState('');
    const [jobno, setjobno] = useState('');
    const [sid, setsid] = useState('');

    const [handleOrderidsend, sethandleOrderidsend] = useState('');

    useEffect(() => {
        handleKeylineFeth();
    }, []);

    const handleKeylineFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.consumablejobdisplay, formData);
            if (response.data.status === "success") {
                setproductname(response.data.productname);
                setproductprise(response.data.productprise);
                setproductdescription(response.data.productdescription);
                setproductImage(response.data.productImage);
                setcustomeraddress(response.data.customeraddress);
                setdate(response.data.date);
                setstatus(response.data.tracker);
                setjobno(response.data.jobno);
                setsid(response.data.sid);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row align-items-center">
                <Loading loading={loading} />
                <div className="col-lg-3 col-md-10 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setSearch(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div className="col-lg-2 ms-lg-auto col-md-2">
                    <div className="me-2">
                        <Form.Select aria-label="Default select example" onChange={handleFilter}>
                            <option disabled>Select filter</option>
                            <option value="All">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Complete</option>
                        </Form.Select>
                    </div>
                </div>
            </div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Job Number</th>
                        <th>Job Name</th>
                        <th>Product Name</th>
                        <th>Product Price</th>
                        <th>Product Description</th>
                        <th>Product Image</th>
                        <th>Customer Address</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData && currentPageData.map((item) => (
                        <tr>
                            <td>{item.orderid}</td>
                            <td>{item.jobname}</td>
                            <td>{item.productname}</td>
                            <td>{item.productprise}</td>
                            <td>{item.productdescription}</td>
                            <td><a href={lib.imgurl + '/' + item.productImage} target="_blank" >View Image</a></td>
                            <td>{item.customeraddress}</td>
                            <td>{item.date}</td>
                            <td>{item.status}</td>
                            <td className="d-flex">
                                <LuEye onClick={() => handleShow(item.orderid)} size={20} />
                                {/* <FaRegEdit onClick={() => handleShows(item.orderid)} size={20} className='ms-3' /> */}
                                <DeleteJob handleJobnumber={item.orderid} handleJobfrom={"consumable"}/>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Consumable</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>

                        <div className='col-md-12 d-flex justify-content-center mb-3'>
                            <span className=' invoce-tittle'>View Job Card</span>
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Job no</label>
                            <input className='form-control' placeholder='Job no' value={jobno} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Customer code</label>
                            <input className='form-control' placeholder='customer code' value={sid} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Product name</label>
                            <input className='form-control' placeholder='Category' value={productname} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Product price</label>
                            <input className='form-control' placeholder='Box style' value={productprise} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Product Description</label>
                            <input className='form-control' placeholder='Sheet size' value={productdescription} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Date</label>
                            <input className='form-control' placeholder='Date' value={date} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Customer Address</label>
                            <input className='form-control' placeholder='Height' value={customeraddress} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Status</label>
                            <input className='form-control' placeholder='Status' value={status} />
                        </div>
                    </div>
                </Modal.Body>
                <div className='row'>
                    <div className='col-lg-2'>
                        <Button className='btn-primary' onClick={handleKeylineFeth}>Feth Data</Button>
                    </div>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Description */}

            <Modal show={shows} onHide={handleCloses} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Description jobnumberdescription={jobnumberdescription} handleJobfrom={'consumableorder'} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloses}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}
export default Consumable