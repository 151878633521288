import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
import Sheetdata from "./sheetdata";
import Loading from "../common/Loading";

const Entersheet = () => {
	const [producttype, setMachine] = useState("N/A");
	const [sheet, setSheet] = useState("N/A");
	const [sheetGsm, setSheetGsm] = useState("N/A");
	const [sheetsize, setSheetsize] = useState("N/A");
	const [coroSheet, setcoroSheet] = useState("N/A");
	const [plyQuality, setplyQuality] = useState("N/A");
	const [anyspecialsheet, setAnyspecialSheet] = useState("N/A");
	const [sheetthickness, setSheetthickness] = useState("N/A");
	const [SheetThicknessaftercrush, setSheetThicknessaftercrush] = useState("N/A");
	const [unickname, setUnickname] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSheetData = async () => {
		const token = localStorage.getItem('token');

		const formData = new FormData();
		formData.append('producttype', producttype);
		formData.append('sheettype', sheet);
		formData.append('sheetgsm', sheetGsm);
		formData.append('totalsize', sheetsize);
		formData.append('plytype', coroSheet);
		formData.append('fludetype', plyQuality);
		formData.append('anyspecialsheet', anyspecialsheet);
		formData.append('sheetthickness', sheetthickness);
		formData.append('sheetthicknesscrash', SheetThicknessaftercrush);
		formData.append('apikey', token);
		formData.append('unickname', unickname);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.sheetdata, formData);
			if (response.data.status === "success") {
				Swal.fire('Success', 'Machine Data Saved successfully!', 'success');
				setLoading(false);
			}
			else {
				Swal.fire({
					title: 'opps......',
					text: response.data.msg,
					icon: 'warning',
					showCancelButton: false,
					confirmButtonText: 'Confirm',
				});
				setLoading(false);
			}
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	function toggleSelect(e) {
		setMachine(e.target.value);
		var selectoption = document.getElementById("selectoption");
		var duplex = document.getElementById("duplex");
		var coro = document.getElementById("coro");

		if (selectoption.value === "duplex") {
			duplex.style.display = "block";
			coro.style.display = "none";
		}
		else {
			if (selectoption.value === "Corrugation") {
				duplex.style.display = "none";
				coro.style.display = "block";
			}
			else {
				duplex.style.display = "none";
				coro.style.display = "none";
			}
		}
	}
	return (
		<div id="theme-default" class="full_block">
			<div id="content">
				<div class="fullbody">
					<Loading loading={loading} />
					<div class="widget_wrap">

						<form class="form_container ">

							<fieldset>

								<div className="row justify-content-center mt-3">
									<div className="col-md-4 ">
										<div className="row ">
											<div style={{ display: 'block' }}>
												<div class="col-md-12">
													<label class="field_title">Sheet Material Name(It is Required to fill)</label>
													<div class="form_input">
														<div class=" alpha">
															<input type="text" onChange={(e) => setUnickname(e.target.value)} required />
														</div>
														<span class="clear"></span>
													</div>
												</div>
											</div>
											<div class="col-md-12 mb-3">
												<label for="toggleSelect">Select Product Type </label> <br />
												<select class="form-select" aria-label="Default select example" id="selectoption" onChange={toggleSelect} required>
													<option selected disabled>Select Product type....</option>
													<option value="duplex">Duplex</option>
													<option value="Corrugation">Corrugation</option>
												</select>
											</div>
											<span class="clear"></span>

											<div id='duplex' style={{ display: 'none' }} className="col-md-12">
												<div className='mb-3'>
													<label for="toggleSelect">Select Sheet Type</label>
													<select class="form-select" aria-label="Default select example" id="selectoption" onChange={(e) => setSheet(e.target.value)} required>
														<option disabled selected>Select style Sheet ....</option>
														<option value="Fbb">Fbb</option>
														<option value="Gb">Gb</option>
														<option value="Wb">Wb</option>
														<option value="Paper">Paper</option>
													</select>
													<span class="clear"></span>
												</div>

												<div className='mb-2'>
													<label class="field_title">My Sheet GSM</label>
													<input type="text" class="form-control" placeholder="My Sheet GSM" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => setSheetGsm(e.target.value)} />
													<span class="clear"></span>
												</div>

												<div className='mb-2'>
													<label class="field_title">Sheet Thickness</label>
													<input type="text" class="form-control" placeholder="Sheet Thickness" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => setSheetsize(e.target.value)} />

												</div>
											</div>

											<div id='coro' style={{ display: 'none' }} className="col-md-12">
												<div className='mb-2'>
													<label for="toggleSelect">Select ply Type</label>
													<select class="form-select" aria-label="Default select example" id="selectoption" onChange={(e) => setcoroSheet(e.target.value)} required>
														<option disabled selected>Select Ply Type</option>
														<option value="3ply">3ply</option>
														<option value="5ply">5ply</option>
														<option value="7ply">7ply</option>
														<option value="9ply">9ply</option>
													</select>
													<span class="clear"></span>
												</div>
												<div className='mb-2'>
													<label for="toggleSelect">Select Flude type</label>
													<select class="form-select" aria-label="Default select example" id="selectoption" onChange={(e) => setplyQuality(e.target.value)} required>
														<option disabled selected>Select Ply Type</option>
														<option value="E flute">E flute</option>
														<option value="B flute">B flute</option>
														<option value="C flute">C flute</option>
														<option value="Narrow">Narrow</option>
														<option value="EE">EE</option>
														<option value="BB">BB</option>
														<option value="EC">EC</option>
														<option value="CB">CB</option>
														<option value="EB">EB</option>
														<option value="Other">Other</option>
													</select>
													<span class="clear"></span>
												</div>

												<div className='mb-2'>
													<label class="field_title">Any Special Sheet </label>
													<input type="text" class="form-control" placeholder="Any Special Sheet " aria-label="Recipient's username" onChange={(e) => setAnyspecialSheet(e.target.value)} aria-describedby="basic-addon2" />
													<span class="clear"></span>
												</div>
												<div className='mb-2'>
													<label class="field_title">Sheet Thickness </label>
													<input type="text" class="form-control" placeholder="Sheet Thickness " aria-label="Recipient's username" onChange={(e) => setSheetthickness(e.target.value)} aria-describedby="basic-addon2" />
													<span class="clear"></span>
												</div>
												<div className='mb-2'>
													<label class="field_title">Sheet Thickness after Crush</label>
													<input type="text" class="form-control" placeholder="Sheet Thickness after" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => setSheetThicknessaftercrush(e.target.value)} />
													<span class="clear"></span>
												</div>
											</div>
										</div>

									</div>


									{/* <div className="col-md-4 border bg-white round p-3">
										<div className="row">


											<div class="col-md-6 mt-3">
												<span className="form-test">Sheet Type:-</span><span className="ms-3 form-test">{machine}</span>
											</div>
											<div class="col-md-6 mt-3">
												<span className="form-test">Sheet Style:-</span><span className="ms-3 form-test">{sheet}</span>
											</div>
											<div class="col-md-6 mt-3">
												<span className="form-test">Sheet Gsm:-</span><span className="ms-3 form-test">{sheetGsm}</span>
											</div>
											<div class="col-md-6 mt-3">
												<span className="form-test">Corogration Sheet Type:-</span><span className="ms-3 form-test">{coroSheet}</span>
											</div>
											<div class="col-md-6 mt-3">
												<span className="form-test">Ply Quality:-</span><span className="ms-3 form-test">{plyQuality}</span>
											</div>
											<div class="col-md-6 mt-3">
												<span className="form-test">Any Sepical Sheet:-</span><span className="ms-3 form-test">{anyspecialsheet}</span>
											</div>
											<div class="col-md-6 mt-3">
												<span className="form-test">Sheet Thickness:-</span><span className="ms-3 form-test">{sheetthickness}</span>
											</div>
											<div class="col-md-6 mt-3">
												<span className="form-test">Sheet Thickness after crush:-</span><span className="ms-3 form-test">{SheetThicknessaftercrush}</span>
											</div>
											<div class="col-md-6 mt-3">
												<span className="form-test">Total Sheet:-</span><span className="ms-3 form-test">{sheetsize}</span>
											</div>


										</div>
									</div> */}

								</div>

							</fieldset>

							<ul>
								<li>
									<div class="form_grid_12">
										<div class="form_input">
											<input type="submit" class="btn_small btn_blue" value="save data" name="savebt" onClick={handleSheetData} />
										</div>
									</div>
								</li>
							</ul>
						</form>
					</div>
				</div>
			</div>
		</div>

	)
}
export default Entersheet;