import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import Header from "./common/header";
import TopMenu from "./common/topmenu";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import lib from "../config/lib";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Swal from 'sweetalert2';
import Accordion from 'react-bootstrap/Accordion';
import { Box, Typography, Stack } from "@mui/material";
import Loading from "../common/Loading";

const Standard = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const [shows, setShows] = useState(false);
    const handleCloses = () => setShows(false);
    const handleShows = () => setShows(true)

    const handleShow = (id, productimage, productname) => {
        setShow(true);
        setProductImage(productimage);
        setFethId(id);
        setJobname(productname);
        handleStanderdFeth(id);
        setLoading(true)
    }

    const [data, setData] = useState([]);
    const [cat, setCat] = useState([]);
    const [subcatdata, setSubcat] = useState([]);

    const [fethid, setFethId] = useState('');
    const [productimage, setProductImage] = useState(''); // img to be set hear

    const [maincat, setMaincat] = useState('');
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [tf, setTf] = useState('');
    const [gf, setGf] = useState('');
    const [df, setDf] = useState('');
    const [margin, setMargin] = useState('');
    const [lock, setLock] = useState('');
    const [If, setIf] = useState('');
    const [wall, setWall] = useState('');
    const [fg, setFtg] = useState('');
    const [In, setIn] = useState('');
    const [inl, setInl] = useState('');
    const [ft, setFt] = useState('');
    const [fl, setFl] = useState('');
    const [lp, setLp] = useState('');
    const [dh, setDh] = useState('');
    const [M, setM] = useState('');
    const [dfh, setDfh] = useState('');
    const [dfd, setDfd] = useState('');
    const [ll, setLl] = useState('');
    const [lu, setLu] = useState('');
    const [lex, setLex] = useState('');
    const [tarc, setTarc] = useState('');
    const [lard, setLard] = useState('');
    const [arcd, setArcd] = useState('');
    const [hd, setHd] = useState('');
    const [hc, setHc] = useState('');
    const [hf, setHf] = useState('');
    const [hcl, setHcl] = useState('');
    const [hft, setHft] = useState('');
    const [ha, setHa] = useState('');
    const [hadf, setHadf] = useState('');
    const [slt, setSlt] = useState('');
    const [Iflp, setIflp] = useState('');
    const [sl, setSl] = useState('');
    const [sh, setSh] = useState('');
    const [hst, setHst] = useState('');
    const [ifl, setIfl] = useState('');
    const [lks, setLks] = useState('');
    const [lkr, setLkr] = useState('');
    const [mgn, setMgn] = useState('');
    const [tmgn, setTmgn] = useState('');
    const [fmgn, setFmgn] = useState('');
    const [Ifld, setIfld] = useState('');
    const [sd, setSd] = useState('');
    const [ifr, setIfr] = useState('');
    const [thsb, setThsb] = useState('');
    const [thbh, setThbh] = useState('');
    const [kanr, setKanr] = useState('');
    const [slth2, setSlth2] = useState('');
    const [iff, setIff] = useState('');
    const [ins, setIns] = useState('');
    const [ilk, setIlk] = useState('');
    const [fif, setFif] = useState('');
    const [cl, setCl] = useState('');
    const [grip, setGrip] = useState('');
    const [gcons, setGcons] = useState('');
    const [sm, setSm] = useState('');
    const [tp, setTp] = useState('');
    const [jobname, setJobname] = useState('');

    const [measer, setMeaser] = useState('');

    const handleOrederNow = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        setLoading(true);
        formData.append('apikey', token);
        formData.append('maincat', maincat);
        formData.append('lenth', length);
        formData.append('width', width);
        formData.append('height', height);
        formData.append('tf', tf);
        formData.append('gf', gf);
        formData.append('df', df);
        formData.append('margin', margin);
        formData.append('lock', lock);
        formData.append('If', If);
        formData.append('wall', wall);
        formData.append('fg', fg);
        formData.append('In', In);
        formData.append('inl', inl);
        formData.append('ft', ft);
        formData.append('fl', fl);
        formData.append('lp', lp);
        formData.append('dh', dh);
        formData.append('M', M);
        formData.append('dfh', dfh);
        formData.append('dfd', dfd);
        formData.append('ll', ll);
        formData.append('lu', lu);
        formData.append('lex', lex);
        formData.append('tarc', tarc);
        formData.append('arcd', arcd);
        formData.append('hd', hd);
        formData.append('hc', hc);
        formData.append('hf', hf);
        formData.append('hcl', hcl);
        formData.append('hft', hft);
        formData.append('ha', ha);
        formData.append('hadf', hadf);
        formData.append('slt', slt);
        formData.append('Iflp', Iflp);
        formData.append('sl', sl);
        formData.append('sh', sh);
        formData.append('hst', hst);
        formData.append('ifl', ifl);
        formData.append('lks', lks);
        formData.append('lkr', lkr);
        formData.append('mgn', mgn);
        formData.append('tmgn', tmgn);
        formData.append('fmgn', fmgn);
        formData.append('Ifld', Ifld);
        formData.append('sd', sd);
        formData.append('ifr', ifr);
        formData.append('thsb', thsb);
        formData.append('thbh', thbh);
        formData.append('kanr', kanr);
        formData.append('slth2', slth2);
        formData.append('iff', iff);
        formData.append('ins', ins);
        formData.append('ilk', ilk);
        formData.append('fif', fif);
        formData.append('cl', cl);
        formData.append('grip', grip);
        formData.append('gcons', gcons);
        formData.append('sm', sm);
        formData.append('tp', tp);
        formData.append('productimg', productimage);
        formData.append('jobname', jobname);

        formData.append('measer', measer);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerdsorders, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Order Created Successfully', 'success');
                handleClose();
                handleCloses();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleProducts();
        handlecat();
        setLoading(false);
    }, []);

    const handleProducts = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('subcat', 'All');
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerds, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handlefavorite = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.addfavourite, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Added To Favourite!', 'success');
                setLoading(false);
            }
        } catch (error) {
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }


    // treeview starts hear


    const handlecat = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        setLoading(true);
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.treecat);
            setCat(response.data);
            setLoading(false);
        } catch (error) {
            console.log("Some error has occurred kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handlesubcat = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append("catid", id);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.treesubcat, formData);
            setSubcat(response.data);
            setLoading(false);
        } catch (error) {
            console.log("Some error has occurred kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleSubcatdisplay = async (subid) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append("subcat", subid);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerds, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.log("Some error has occurred kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // treeview ends hear


    const [lenthds, setLenthds] = useState('');
    const [widthds, setWidthds] = useState();
    const [heightds, setHeightds] = useState('');
    const [tfds, setTfds] = useState('');
    const [gfds, setGfds] = useState('');
    const [dfds, setDfds] = useState('');
    const [marginds, setMarginds] = useState('');
    const [lockds, setLockds] = useState('');
    const [ifds, setIfds] = useState('');
    const [wallds, setWallds] = useState('');
    const [ftgds, setFtgds] = useState('');
    const [inds, setInds] = useState('');
    const [inlds, setInlds] = useState('');
    const [flds, setFlds] = useState('');
    const [lpds, setLpds] = useState('');
    const [dhds, setDhds] = useState('');
    const [mds, setMds] = useState('');
    const [dfhds, setDfhds] = useState('');
    const [dfdds, setDfdds] = useState('');
    const [llds, setLlds] = useState('');
    const [luds, setLuds] = useState('');
    const [lexds, setLexds] = useState('');
    const [traceds, setTracds] = useState('');
    const [lardds, setLardds] = useState('');
    const [arcdds, setArcdds] = useState('');
    const [hdds, setHdds] = useState('');
    const [hcds, setHcds] = useState('');
    const [hfds, setHfds] = useState('');
    const [hclds, setHclds] = useState('');
    const [hftds, setHftds] = useState('');
    const [hads, setHads] = useState('');
    const [hadfds, setHadfds] = useState('');

    const [ftds, setFtds] = useState('');
    const [sltds, setSltds] = useState('');
    const [iflpds, setIflpds] = useState('');
    const [slds, setSlds] = useState('');
    const [shds, setShds] = useState('');
    // const [hst, setHst] = useState('');
    const [iflds, setIflds] = useState('');
    const [lksds, setLksds] = useState('');
    const [lkrds, setLkrds] = useState('');
    const [mgnds, setMgnds] = useState('');
    const [tmgnds, setTmgnds] = useState('');
    const [ifldds, setIfldds] = useState('');
    const [sdds, setSdds] = useState('');
    const [ifrds, setIfrds] = useState('');
    const [thsdds, setThsdds] = useState('');
    const [thbhds, setThbhds] = useState('');
    const [kanrds, setKanrds] = useState('');
    const [slth2ds, setSlth2ds] = useState('');
    const [iffds, setIffds] = useState('');
    const [insds, setInsds] = useState('');
    const [ilkds, setIlkds] = useState('');
    const [fifds, setFifds] = useState('');
    const [clds, setClds] = useState('');
    const [gripds, setGripds] = useState('');
    const [gconsds, setGconsds] = useState('');
    const [smds, setSmds] = useState('');
    const [tpds, setTpds] = useState('');


    const handleStanderdFeth = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append("id", id);
        setLoading(true);
        try {
            const data = await axios.post(lib.apiURL + '/' + lib.apis.standerdsaccessfeth, formData);
            const response = data.data;
            setLenthds(response.lenth);
            setWidthds(response.widthds);
            setHeightds(response.heightds);
            setTfds(response.tfds);
            setGfds(response.gfds);
            setDfds(response.dfds);
            setMarginds(response.marginds);
            setLockds(response.lockds);
            setIfds(response.ifds);
            setWallds(response.wallds);
            setFtgds(response.ftgds);
            setInds(response.inds);
            setInlds(response.inlds);
            setFlds(response.flds);
            setLpds(response.lpds);
            setDhds(response.dhds);
            setMds(response.mds);
            setDfhds(response.dfhds);
            setDfdds(response.dfdds);
            setLlds(response.llds);
            setLuds(response.luds);
            setLexds(response.lexds);
            setTracds(response.traceds);
            setLardds(response.lardds);
            setArcdds(response.arcdds);
            setHdds(response.hdds);
            setHcds(response.hcds);
            setHfds(response.hfds);
            setHclds(response.hclds);
            setHftds(response.hftds);
            setHads(response.hads);
            setHadfds(response.hadfds);

            setFtds(response.ftds);
            setSltds(response.sltds);
            setIflpds(response.iflpds);
            setSlds(response.slds);
            setShds(response.shds);
            // setHst(response.hst);
            setIflds(response.iflds);
            setLksds(response.lksds);
            setLkrds(response.lkrds);
            setMgnds(response.mgnds);
            setTmgnds(response.tmgnds);
            setIfldds(response.ifldds);
            setSdds(response.sdds);
            setIfrds(response.ifrds);
            setThsdds(response.thsdds);
            setThbhds(response.thbhds);
            setKanrds(response.kanrds);
            setSlth2ds(response.slth2ds);
            setIffds(response.iffds);
            setInsds(response.insds);
            setIlkds(response.ilkds);
            setFifds(response.fifds);
            setClds(response.clds);
            setGripds(response.gripds);
            setGconsds(response.gconsds);
            setSmds(response.smds);
            setTpds(response.tpds);
            setLoading(false);
        } catch (error) {
            console.log("Some error has occurred kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const [orderiddisplay, setOrderiddisplay] = useState('');
    const [date, setDate] = useState('');

    const handleModleOpen = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('lenth', length);
        formData.append('width', width);
        formData.append('height', height);
        formData.append('jobfrom', 'standerdorder');
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.checkorderdetails, formData);
            if (response.data.status === "success") {
                handleShows();
                setOrderiddisplay(response.data.orderid);
                setDate(response.data.date);
                setLoading(false);
            }
        } catch (error) {
            handleOrederNow();
            setLoading(false);
        }
    }

    const handleNo = () => {
        handleClose();
        handleCloses();
        setLoading(false);
    }

    const [sheetdata, setSheetdata] = useState([]);

    useEffect(() => {
        handleSheetData();
    }, []);


    const handleSheetData = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.keysheetdata, formData)
            setSheetdata(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div id="theme-default" className="full_block">
            <Header />
            <TopMenu />
            <div id="content">
                <div class="fullbody">
                    <Loading loading={loading} />
                    {/* <>Treeview starts hear</> */}
                    <div className="treestyle">
                        <Box width="100%">
                            <Accordion defaultActiveKey="0">
                                {cat && cat.map((item) => (
                                    <Accordion.Item key={item.catid} eventKey={item.catid} onClick={() => handlesubcat(item.catid)}>
                                        <Accordion.Header>
                                            <div>{item.cat}</div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {subcatdata && subcatdata.map((subitem) => (
                                                <div className="mt-3"><a href="#" onClick={() => handleSubcatdisplay(subitem.subcatid)}>{subitem.subcat}</a></div>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Box>
                    </div>

                    {/* <>Treeview ends hear</> */}

                    <div class="widget_content centerimgpart">
                        <div class="gall_wrap">
                            {data && data.map((item) => (
                                <Card className="card-size">
                                    <Card.Img variant="top" src={lib.imgurl + "/" + item.productimage} style={{ width: 'auto', height: 200 }} />
                                    <Card.Body>
                                        <Card.Title>{item.productname}</Card.Title>
                                        <Card.Text>
                                            {item.productdescription}
                                        </Card.Text>
                                        <Button variant="primary" onClick={() => handleShow(item.id, item.productimage, item.productname)}>Buy Now</Button>
                                        <Button variant="primary" style={{ marginTop: '5px' }} onClick={() => handlefavorite(item.id)}>Add To Favourite</Button>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                    </div>

                    <Modal size="xl" show={show} onHide={handleClose} centered className="mt-4">
                        <Modal.Header closeButton>
                            <Modal.Title>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row justify-content-center">
                                <div className="col-md-5 ">
                                    <Image src={lib.imgurl + "/" + productimage} alt="image view" className="img-fluid  border round" />
                                </div>

                                {/* MAIN PANEL  */}

                                <div className="col-md-6">
                                    <div className="row" >
                                        <div className="col-md-12">
                                            <div className='d-flex'><label>Material</label></div>
                                            <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setMaincat(e.target.value)}>
                                                <option selected disabled>Select Material</option>
                                                <option value="Duplex">Duplex</option>
                                                <option value="Currogated">Currogated</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-md-12  mt-2">
                                            <div className='d-flex'><label>Measurement</label></div>
                                            <Form.Select aria-label="Default select measurement" className="mt-2" onChange={(e) => setMeaser(e.target.value)}>
                                                <option selected disabled>Select measurement</option>
                                                <option value="INCH">INCH</option>
                                                <option value="MM">MM</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <div className='d-flex'><label>Quality</label></div>
                                            <input type="text" id="input-name" placeholder="Quality" />
                                        </div>
                                        <div className='col-md-6 mt-2'>
                                            <div className='d-flex'><label>Sheet Thickness</label></div>
                                            <Form.Select aria-label="Default select example" id="firstselect" className='mt-2' >
                                                <option selected disabled>Select sheet type</option>
                                                {sheetdata && sheetdata.map((item) => (
                                                    <option value={item.unickname}>{item.unickname}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                        <Accordion className="mt-2">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Main pannel</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">

                                                        {lenthds === "true" ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Length</label></div>
                                                                <input type="text" id="input-name" placeholder="Length" onChange={(e) => setLength(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {widthds === "true" ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Width</label></div>
                                                                <input type="text" id="input-name" placeholder="Width" onChange={(e) => setWidth(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {heightds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Height</label></div>
                                                                <input type="text" id="input-name" placeholder="Height" onChange={(e) => setHeight(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {tfds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Tf</label></div>
                                                                <input type="text" id="input-name" placeholder="Tf" onChange={(e) => setTf(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {gfds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Gf</label></div>
                                                                <input type="text" id="input-name" placeholder="GF" onChange={(e) => setGf(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {dfds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Df</label></div>
                                                                <input type="text" id="input-name" placeholder="DF" onChange={(e) => setDf(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {lockds === 'true' ? (
                                                            <div className="col-md-3 mt-2">
                                                                <div className='d-flex'><label>Lock</label></div>
                                                                <input type="text" id="input-name" placeholder="Lock" onChange={(e) => setLock(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {wallds === 'true' ? (
                                                            <div className="col-md-3 mt-2">
                                                                <div className='d-flex'><label>Wall</label></div>
                                                                <input type="text" id="input-name" placeholder="Wall" onChange={(e) => setWall(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {ftgds === 'true' ? (
                                                            <div className="col-md-3 mt-2">
                                                                <div className='d-flex'><label>Ftg</label></div>
                                                                <input type="text" id="input-name" placeholder="Ftg" onChange={(e) => setFtg(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {inds === 'true' ? (
                                                            <div className="col-md-3 mt-2">
                                                                <div className='d-flex'><label>In</label></div>
                                                                <input type="text" id="input-name" placeholder="In" onChange={(e) => setIn(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {inlds === 'true' ? (
                                                            <div className="col-md-3 mt-2">
                                                                <div className='d-flex'><label>Inl</label></div>
                                                                <input type="text" id="input-name" placeholder="Inl" onChange={(e) => setInl(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>2nd pannel</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">

                                                        {/* 2ND PANEL  */}

                                                        {ftds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Ft</label></div>
                                                                <input type="text" id="input-name" placeholder="Ft" onChange={(e) => setFt(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {flds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Fl</label></div>
                                                                <input type="text" id="input-name" placeholder="Fl" onChange={(e) => setFl(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {lpds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Lp</label></div>
                                                                <input type="text" id="input-name" placeholder="Lp" onChange={(e) => setLp(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>3rd pannel Dust Flap</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        {/* (3RD PANEL OR ANY OTHER NAME LIKE DUST FLAP  ) */}

                                                        {dhds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Dh</label></div>
                                                                <input type="text" id="input-name" placeholder="Dh" onChange={(e) => setDh(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {mds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>M</label></div>
                                                                <input type="text" id="input-name" placeholder="M" onChange={(e) => setM(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {dfhds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Dfh</label></div>
                                                                <input type="text" id="input-name" placeholder="Dfh" onChange={(e) => setDfh(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {dfdds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Dfd</label></div>
                                                                <input type="text" id="input-name" placeholder="Dfd" onChange={(e) => setDfd(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>4rth pannel Lock</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        {/* 4th panel   lock  */}

                                                        {llds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Ll</label></div>
                                                                <input type="text" id="input-name" placeholder="Ll" onChange={(e) => setLl(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {luds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Lu</label></div>
                                                                <input type="text" id="input-name" placeholder="Lu" onChange={(e) => setLu(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {lexds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Lex</label></div>
                                                                <input type="text" id="input-name" placeholder="Lex" onChange={(e) => setLex(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {traceds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Tarc</label></div>
                                                                <input type="text" id="input-name" placeholder="Tarc" onChange={(e) => setTarc(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {lardds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Lard</label></div>
                                                                <input type="text" id="input-name" placeholder="Lard" onChange={(e) => setLard(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {arcdds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Arcd</label></div>
                                                                <input type="text" id="input-name" placeholder="Arcd" onChange={(e) => setArcd(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Holder Pannel</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        {/* HOLDER PANEL  */}

                                                        {hdds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Hd</label></div>
                                                                <input type="text" id="input-name" placeholder="Hd" onChange={(e) => setHd(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {hcds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Hc</label></div>
                                                                <input type="text" id="input-name" placeholder="Hc" onChange={(e) => setHc(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {hfds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Hf</label></div>
                                                                <input type="text" id="input-name" placeholder="Hf" onChange={(e) => setHf(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {hclds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Hcl</label></div>
                                                                <input type="text" id="input-name" placeholder="Hcl" onChange={(e) => setHcl(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {hftds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Hft</label></div>
                                                                <input type="text" id="input-name" placeholder="Hft" onChange={(e) => setHft(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {hads === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Ha</label></div>
                                                                <input type="text" id="input-name" placeholder="Ha" onChange={(e) => setHa(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {hadfds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Hadf</label></div>
                                                                <input type="text" id="input-name" placeholder="Hadf" onChange={(e) => setHadf(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>Other variables</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        {/* MORE VARIABLES */}

                                                        {sltds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Slt</label></div>
                                                                <input type="text" id="input-name" placeholder="Slt" onChange={(e) => setSlt(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {iflpds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Iflp</label></div>
                                                                <input type="text" id="input-name" placeholder="Iflp" onChange={(e) => setIflp(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {slds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Sl</label></div>
                                                                <input type="text" id="input-name" placeholder="Sl" onChange={(e) => setSl(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {shds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Sh</label></div>
                                                                <input type="text" id="input-name" placeholder="Sh" onChange={(e) => setSh(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {hst === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Hst</label></div>
                                                                <input type="text" id="input-name" placeholder="Hst" onChange={(e) => setHst(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {iflds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>If1</label></div>
                                                                <input type="text" id="input-name" placeholder="If1" onChange={(e) => setIfl(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {flds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Fl</label></div>
                                                                <input type="text" id="input-name" placeholder="Fl" onChange={(e) => setFl(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {lksds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Lks</label></div>
                                                                <input type="text" id="input-name" placeholder="Lks" onChange={(e) => setLks(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {lkrds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Lkr</label></div>
                                                                <input type="text" id="input-name" placeholder="Lkr" onChange={(e) => setLkr(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {mgnds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Mgn</label></div>
                                                                <input type="text" id="input-name" placeholder="Mgn" onChange={(e) => setMgn(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {tmgnds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Tmgn</label></div>
                                                                <input type="text" id="input-name" placeholder="Tmgn" onChange={(e) => setTmgn(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {fmgn === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Fmgn</label></div>
                                                                <input type="text" id="input-name" placeholder="Fmgn" onChange={(e) => setFmgn(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {ifldds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Ifld</label></div>
                                                                <input type="text" id="input-name" placeholder="Ifld" onChange={(e) => setIfld(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {sdds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Sd</label></div>
                                                                <input type="text" id="input-name" placeholder="Sd" onChange={(e) => setSd(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {ifds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>If</label></div>
                                                                <input type="text" id="input-name" placeholder="If" onChange={(e) => setIf(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {ifrds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Ifr</label></div>
                                                                <input type="text" id="input-name" placeholder="Ifr" onChange={(e) => setIfr(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {thsb === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Thsb</label></div>
                                                                <input type="text" id="input-name" placeholder="Thsb" onChange={(e) => setThsb(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {thbhds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Thbh</label></div>
                                                                <input type="text" id="input-name" placeholder="Thbh" onChange={(e) => setThbh(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {kanrds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Kanr</label></div>
                                                                <input type="text" id="input-name" placeholder="Kanr" onChange={(e) => setKanr(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {slth2ds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Slth2</label></div>
                                                                <input type="text" id="input-name" placeholder="Slth2" onChange={(e) => setSlth2(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {iffds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Iff</label></div>
                                                                <input type="text" id="input-name" placeholder="Iff" onChange={(e) => setIff(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {insds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Ins</label></div>
                                                                <input type="text" id="input-name" placeholder="Ins" onChange={(e) => setIns(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {ilkds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Ilk</label></div>
                                                                <input type="text" id="input-name" placeholder="Ilk" onChange={(e) => setIlk(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {fifds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Fif</label></div>
                                                                <input type="text" id="input-name" placeholder="Fif" onChange={(e) => setFif(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {clds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Cl</label></div>
                                                                <input type="text" id="input-name" placeholder="Cl" onChange={(e) => setCl(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {gripds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Grip</label></div>
                                                                <input type="text" id="input-name" placeholder="Grip" onChange={(e) => setGrip(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {gconsds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Gcons</label></div>
                                                                <input type="text" id="input-name" placeholder="Gcons" onChange={(e) => setGcons(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {marginds === 'true' ? (
                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Margin</label></div>
                                                                <input type="text" id="input-name" placeholder="Margin" onChange={(e) => setMargin(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {smds === 'true' ? (

                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Sm</label></div>
                                                                <input type="text" id="input-name" placeholder="Sm" onChange={(e) => setSm(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {sdds === 'true' ? (

                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Sd</label></div>
                                                                <input type="text" id="input-name" placeholder="Sd" onChange={(e) => setSd(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {tpds === 'true' ? (

                                                            <div className="col-md-4 mt-2">
                                                                <div className='d-flex'><label>Tp</label></div>
                                                                <input type="text" id="input-name" placeholder="Tp" onChange={(e) => setTp(e.target.value)} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                    </div>
                                </div>

                            </div>

                            <input type="submit" style={{ marginTop: '15px', clear: 'both' }} className="upload-button" onClick={handleModleOpen} />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>

                        </Modal.Footer>
                    </Modal>

                    {/* standerd Yes no modle has to be created hear */}
                    <Modal
                        show={shows}
                        // onHide={() => setShows(false)}
                        onHide={handleCloses}
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Please Check The Details and Proceed for order
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="row">
                                <h3>Order Allready Created on {date} with JobNumber {orderiddisplay}</h3>
                                <div className="col-md-1 mt-3">
                                    <Button onClick={handleOrederNow}>Yes</Button>
                                </div>
                                <div className="col-md-1 mt-3">
                                    <Button onClick={handleNo}>No</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        </div>
    )
}
export default Standard;