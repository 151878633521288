import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './main';
import Login from './auth/login';
import Register from './auth/register';
import ForgotPassword from './auth/ForgotPassword';
import Dashboard from './user/Dashboard';
import Standard from './user/standerd';
import MyOrder from './user/myorderfilter';
import PaymentHistory from './user/paymenthistory';
import UserProfile from './user/userprofile';
import Quation from './user/quation';
import AgentDashbord from './user/agent/Dashboard';
import CustomerOrder from './user/customorder';
import { useNavigate } from "react-router-dom";
import Consumable from './user/consumable';
import Keyline from './user/keyline';
import Support from './user/Support';
import AboutUs from './landingpage/AboutUs'
import ContactUs from './landingpage/ContactUs';

const App = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/')
    }
  }, []);
  
  return (
    <Routes>
      <Route path='/' element={<Main />} />
      <Route path='/about' element={<AboutUs />} />
      <Route path='/contactus' element={<ContactUs />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forgotPassword' element={<ForgotPassword />} />
      <Route path='/register' element={<Register />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/standerd' element={<Standard />} />
      <Route path='/myorders' element={<MyOrder />} />
      <Route path='/paymenthistory' element={<PaymentHistory />} />
      <Route path='/usermanage' element={<UserProfile />} />
      <Route path='/quation' element={<Quation />} />
      <Route path='/customorder' element={<CustomerOrder />} />
      <Route path='/consumables' element={<Consumable />} />
      <Route path='/keyline' element={<Keyline />} />
      <Route path='/Support' element={<Support />} />
      <Route path='/agentdashboard' element={<AgentDashbord />} />
    </Routes>
  );
};

export default App;

