import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import lib from "../config/lib";
import axios from 'axios';
import Swal from 'sweetalert2'
import Loading from "../common/Loading";

const ProfileDetails = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [cname, setCname] = useState('');
    const [gstno, setGstno] = useState('');
    const [address, setAddress] = useState('');
    const [ccode, setCcode] = useState('');
    const [designer, setDesignerNumber] = useState('');
    const [marketNumber, setMarketNumber] = useState('');
    const [wagroup, setWaGroup] = useState('');
    const [loading, setLoading] = useState(false);

    const handleProfileUpdate = async (event) => {
        event.preventDefault();
        setLoading(true);
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('name', name);
        formData.append('mobile', mobile);
        formData.append('email', email);
        formData.append('cname', cname);
        formData.append('gstno', gstno);
        formData.append('address', address);
        formData.append('customercode', ccode);
        formData.append('designernumber', designer);
        formData.append('marketpersonnumber', marketNumber);
        formData.append('groupname', wagroup);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.updateprofile, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Details Updated Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text:   'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleFethData();
    }, []);

    const handleFethData = async () => {
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('apikey', token);
            setLoading(true);
            const response = await axios.post(lib.apiURL + '/' + lib.apis.profile, formData);
            if (response.data.status === "success") {
                setName(response.data.name);
                setMobile(response.data.mobile);
                setEmail(response.data.email);
                setCname(response.data.cname);
                setGstno(response.data.gstno);
                setAddress(response.data.address);
                setCcode(response.data.ccode);
                setDesignerNumber(response.data.dnumber);
                setMarketNumber(response.data.mkt);
                setWaGroup(response.data.groupname);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <div class="fullbody">
                <div class="grid_container">
                    <Loading loading={loading} />
                    <div class="grid_12 full_block">

                        {/* <h3>My Profile Details</h3> */}

                        <div class="widget_content">
                            <h3></h3>
                            <form class="form_container">
                                <ul>
                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Customer Name</label>
                                            <div class="form_input">
                                                <input name="name" type="text" value={name} onChange={(e) => { setName(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>

                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Owner Mobile Number</label>
                                            <div class="form_input">
                                                <input name="mobile" type="text" value={mobile} onChange={(e) => { setMobile(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>

                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Email-Id</label>
                                            <div class="form_input">
                                                <input name="email" type="text" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>

                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Company Name</label>
                                            <div class="form_input">
                                                <input name="cname" type="text" value={cname} onChange={(e) => { setCname(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>

                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Gst Number</label>
                                            <div class="form_input">
                                                <input name="gstno" type="text" value={gstno} onChange={(e) => { setGstno(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>

                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Address</label>
                                            <div class="form_input">
                                                <input name="address" type="text" value={address} onChange={(e) => { setAddress(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>

                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Designer Number</label>
                                            <div class="form_input">
                                                <input name="designernumber" type="text" value={designer} onChange={(e) => { setDesignerNumber(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>

                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Market Person Number</label>
                                            <div class="form_input">
                                                <input name="marketpersonnumber" type="text" value={marketNumber} onChange={(e) => { setMarketNumber(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>

                                    <li class="form_grid_2">
                                        <div>
                                            <label class="field_title">Whats App Group Name</label>
                                            <div class="form_input">
                                                <input name="groupname" type="text" value={wagroup} onChange={(e) => { setWaGroup(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="form_grid_2">
                                        <div>
                                            {/* <label class="field_title">Customer Code</label> */}
                                            <div class="form_input">
                                                <input name="customercode" type="hidden" value={ccode} onChange={(e) => { setCcode(e.target.value) }} required />
                                            </div>
                                        </div>
                                    </li>
                                    <li class="form_grid_8">
                                        <div>
                                            <div class="form_input">
                                                <input type="submit" class="btn_small btn_blue" value="Update Data" name="upudata" onClick={handleProfileUpdate} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <span class="clear"></span>
        </div>
    )
}
export default ProfileDetails;