import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assect/css/custom.css';
import lib from "../../config/lib";
import axios from 'axios';
import Swal from 'sweetalert2'
import Loading from "../../common/Loading";

const Wallet = () => {
    const [walletbalance, setWalletBalance] = useState('');
    const [accno, setAccNo] = useState('');
    const [ifsc, setifsc] = useState('');
    const [bankname, setBankName] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleFethData();
    }, []);

    const handleFethData = async () => {
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('apikey', token);
            setLoading(true);
            const response = await axios.post(lib.apiURL + '/' + lib.apis.walletbalance, formData);
            if (response.data.status === "success") {
                setWalletBalance(response.data.wallet);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleWithdraw = async (event) => {
        event.preventDefault();
        setLoading(true);
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('accno', accno);
        formData.append('ifsc', ifsc);
        formData.append('bankname', bankname);
        formData.append('amount', amount);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.withdraw, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Request Rased Successfully Wait for 24-48 hrs Payment will Credit to your account!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div>
            <div class="fullbody">
                <div class="grid_container">
                    <Loading loading={loading} />
                    <div class="grid_12 full_block">

                        <h3 className="login-text">Total Payment in Your Wallet :- Rs {walletbalance} </h3>

                        <div class="widget_content">
                            <h3></h3>
                            <form class="form_container">
                                <ul>

                                    <div className="form_grid_6">
                                        <li class="form_grid_4">
                                            <div>
                                                <label class="field_title">Bank Account Number</label>
                                                <div class="form_input">
                                                    <input type="text" required onChange={(e) => setAccNo(e.target.value)} />
                                                </div>
                                            </div>
                                        </li>

                                        <li class="form_grid_4">
                                            <div>
                                                <label class="field_title">IFSC Coad</label>
                                                <div class="form_input">
                                                    <input type="text" required onChange={(e) => setifsc(e.target.value)} />
                                                </div>
                                            </div>
                                        </li>

                                        <li class="form_grid_4">
                                            <div>
                                                <label class="field_title">Bank Name</label>
                                                <div class="form_input">
                                                    <input type="text" required onChange={(e) => setBankName(e.target.value)} />
                                                </div>
                                            </div>
                                        </li>

                                        <li class="form_grid_4">
                                            <div>
                                                <label class="field_title">Amount</label>
                                                <div class="form_input">
                                                    <input type="text" required onChange={(e) => setAmount(e.target.value)} />
                                                </div>
                                            </div>
                                        </li>
                                    </div>

                                    <div className="form_grid_6 border bg-white round p-3">
                                        <div className="row p-3">


                                            <div class="col-md-6 mt-3">
                                                <span className="form-test">Tittle:-</span><span className="ms-3 form-test">value</span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <span className="form-test">Tittle:-</span><span className="ms-3 form-test">value</span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <span className="form-test">Tittle:-</span><span className="ms-3 form-test">value</span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <span className="form-test">Tittle:-</span><span className="ms-3 form-test">value</span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <span className="form-test">Tittle:-</span><span className="ms-3 form-test">value</span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <span className="form-test">Tittle:-</span><span className="ms-3 form-test">value</span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <span className="form-test">Tittle:-</span><span className="ms-3 form-test">value</span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <span className="form-test">Tittle:-</span><span className="ms-3 form-test">value</span>
                                            </div>
                                        </div>
                                    </div>

                                    <li class="form_grid_12">
                                        <div>
                                            <div class="form_input">
                                                <input type="submit" class="btn_small btn_blue" value="Update Data" name="upudata" onClick={handleWithdraw} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <span class="clear"></span>
        </div>
    )
}
export default Wallet;