import React, { Fragment, useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./common/header";
import TopMenu from "./common/topmenu";
import { FiSend } from "react-icons/fi";
import axios from 'axios';
import lib from "../config/lib";
import Loading from "../common/Loading";

const Support = () => {

    const [inputText, setInputText] = useState('');
    const [messages, setMessages] = useState([]);
    const chatListRef = useRef(null);
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [mobile, setMobile] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleSupportNumber();
        const welcomeMessage = {
            id: '0',
            text: 'Welcome! How can we assist you today?',
            align: 'left',
            timestamp: getCurrentTime(),
        };

        setMessages([welcomeMessage]);
        scrollToBottom();
    }, []);

    const handleSupportNumber = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        setLoading(true);
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.supportdetails);
            setEmail(response.data.email);
            setAddress(response.data.address);
            setMobile(response.data.mobile);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSend();
        }
    };

    const handleSend = async () => {
        if (inputText.trim() === '') {
            return;
        }

        const newMessage = createMessage(inputText, 'right');

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('msg', inputText);
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.chatbot,
                formData
            );

            const botReply = createMessage(response.data.msg, 'left');
            setMessages([...messages, newMessage, botReply]);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching bot response:", error);
            // Handle error if the request failed
            const errorMessage = createMessage("An error occurred while fetching the response.", 'left');
            setMessages([...messages, newMessage, errorMessage]);
            setLoading(false);
        }

        setInputText('');
        scrollToBottom();
    };

    const createMessage = (text, align) => ({
        id: String(messages.length + 1),
        text,
        align,
        timestamp: getCurrentTime(),
    });

    const getCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
    };

    const scrollToBottom = () => {
        if (chatListRef.current) {
            chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
        }
    };
    return (
        <Fragment>

            <div id="theme-default" className="full_block">
                <Header />
                <TopMenu />

                <div id="content">
                    <Loading loading={loading} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="chat-tittle">Let's start a Chat</h3>
                            </div>
                        </div>
                        <div className="row justify-content-between border round p-3 cus-height">
                            <div className="col-lg-2 col-md-4 d-flex justify-content-center">
                                <div className="box-support">
                                    <ul>
                                        <li className="top_bar">
                                            <div className="box-lable">E mail</div> <br />
                                            <div className="box-lables">{email}</div>
                                        </li>
                                        <li className="top_bar">
                                            <div className="box-lable">Contact</div> <br />
                                            <div className="box-lables">{mobile}</div>
                                        </li>
                                        <li className="top_bar">
                                            <div className="box-lable">Location</div> <br />
                                            <div className="box-lables">{address}</div>
                                        </li>

                                        <div className="Extramargin">

                                        </div>
                                    </ul>
                                </div>

                            </div>

                            <div className="col-lg-10 col-md-8">
                                <div className="chat-container">
                                    <div className="chat" ref={chatListRef}>
                                        <ul className="chat-list">
                                            {messages && messages.map((item) => (
                                                <li key={item.id} className={item.align === 'right' ? 'right-message' : 'left-message'}>
                                                    <p className="m-0">{item.text}</p>
                                                    <span className="timestamp">{item.timestamp}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="input-container">
                                            <input
                                                placeholder={'Type your message...'}
                                                value={inputText}
                                                onChange={(e) => setInputText(e.target.value)}
                                                onKeyPress={handleKeyPress}
                                                className="form-control"
                                            />
                                            <button onClick={handleSend} className="btn btn-primary custom-p">
                                                <FiSend size={24} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </Fragment>
    );
};

export default Support;
