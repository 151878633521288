import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import Header from "./common/header";
import TopMenu from "./common/topmenu";
import ProfileDetails from "./profiledetails";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ChangePassword from "./changepassword";
import PrintingMachine from "./printingmachine";
import DieMachine from "./diemachine";
import MachineData from "./machinedata";
import Sheetdata from "./sheetdata";
import Entersheet from "./entersheetdata";
import SubViewer from './subViewer'

const UserProfile = () => {

    const subrole = localStorage.getItem("subrole");

    return (
        <div id="theme-default" className="full_block">
            <Header />
            <TopMenu />
            <div id="content">
                <div className="fullbody">
                    <div className="buttondiv">
                        <Tabs
                            defaultActiveKey="myprofile"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{ fontSize: '20px' }}
                        >
                            <Tab eventKey="myprofile" title="My Profile">
                                <ProfileDetails />
                            </Tab>
                            <Tab eventKey="changepass" title="Change Password">
                                <ChangePassword />
                            </Tab>
                            <Tab eventKey="printmachine" title="Printing Machine Data">
                                <PrintingMachine />
                            </Tab>
                            <Tab eventKey="diemachine" title="Die Machine Data">
                                <DieMachine />
                            </Tab>
                            <Tab eventKey="entermachine" title="Enter Machine Data">
                                <MachineData />
                            </Tab>
                            <Tab eventKey="sheetmachine" title="Sheet Machine Data">
                                <Sheetdata />
                            </Tab>
                            <Tab eventKey="entersheet data" title="Enter Sheet Data">
                                <Entersheet />
                            </Tab>
                            {subrole === "yes" ? (
                                <Tab eventKey="Sub Viewer" title="Sub User">
                                    <SubViewer />
                                </Tab>
                            ) : (
                                <></>
                            )}


                        </Tabs>
                    </div>


                </div>
            </div>
        </div>
    )
}
export default UserProfile;