import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import TopMenu from './common/topmenu';
import Header from './common/header';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
// import logo from './assect/images/logo.jpg';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
// import { SpaceBar } from '@mui/icons-material';
import FormSelect from 'react-bootstrap/FormSelect'
// import Modal from 'react-bootstrap/Modal';
// import Consumables from './consumableModal/consumables'
import Loading from '../common/Loading';

const Consumable = (props) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setId(id);
    setShow(true);
  }
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState('');
  const [data, setData] = useState([]);

  // ordernow query
  const [catmain, setCatmain] = useState('');
  const [quality, setQuality] = useState('');
  const [matrixsize, setMatrixSize] = useState('');
  const [type, setType] = useState('');
  const [quantity, setQuantity] = useState('');
  const [rubbersize, setRubberSize] = useState('');

  const handleBuyNow = async () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('apikey', token);
    formData.append('productid', id);
    formData.append('catmain', catmain);
    formData.append('quality', quality);
    formData.append('matrixsize', matrixsize);
    formData.append('type', type);
    formData.append('quantity', quantity);
    formData.append('rubbersize', rubbersize);
    setLoading(true);
    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.consumbalorder, formData);
      if (response.data.status == "success") {
        Swal.fire('Success', 'Product Booked!', 'success');
        setLoading(false);
      }
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
      Swal.fire({
        title: 'opps......',
        text: 'Somthing Went Wrong',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Confirm',
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    handleProducts();
  }, []);

  const handleProducts = async () => {

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('apikey', token);
    setLoading(true);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.consumable, formData);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
      setLoading(false);
    }
  }



  function startform(s) {
    setCatmain(s.target.value);
    var Selectfirst = document.getElementById("Selectfirst");
    var Creasing = document.getElementById("Creasing");
    var Rubber = document.getElementById("Rubber");

    console.log(Selectfirst.value);

    if (Selectfirst.value === "Creasing matrix") {
      Creasing.style.display = 'block';
      Rubber.style.display = "none";

    }
    else {
      if (Selectfirst.value === "Rubber") {
        Creasing.style.display = "none";
        Rubber.style.display = 'block';
      }
    }
  }

  return (
    <div id="theme-default" className="full_block">
      <Header />
      <TopMenu />
      <div id="content">
        <Loading loading={loading} />
        <div class="fullbody">
          {data && data.map((item) => (
            <Card
              style={{
                width: '100%',
                maxWidth: '250px',
                margin: '20px auto',
                textAlign: 'center',
                float: 'left',
                marginLeft: '20px'
              }}
            >
              <Card.Img variant="top" src={lib.imgurl + "/" + item.productImage} />
              <Card.Body>
                <Card.Title>{item.productname}</Card.Title>
                <Card.Text>
                  {item.productdescription}
                </Card.Text>
                <Card.Text>
                  Rs {item.productprise} /-
                </Card.Text>

                <Button variant="primary" onClick={() => handleShow(item.id)}>Buy Now</Button>
              </Card.Body>
            </Card>
          ))}
        </div>
        <Modal size="lg" show={show} onHide={handleClose} centered className="mt-4">
          <Modal.Header closeButton>
            <Modal.Title>Consumables</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              <div className="col-md-12 mt-2">
                <label className='d-flex'>Category</label>
                <Form.Select id="Selectfirst" style={{ marginTop: '10px' }} onChange={(e) => startform(e)}>
                  <option selected disabled>Select Category type</option>
                  <option value="Creasing matrix">Creasing matrix</option>
                  <option value="Rubber">Rubber</option>
                </Form.Select>
              </div>
              <div id="Creasing" style={{ display: 'none' }}>
                <div className="col-md-12 mt-2">
                  <label className='d-flex'>Quality</label>
                  <Form.Select className="form-select" aria-label="Default select example" onChange={(e) => setQuality(e.target.value)} >
                    <option selected disabled>Select Quality</option>
                    <option value="Long run">Long run</option>
                    <option value="Medium run">Medium run</option>
                    <option value="Short run">Short run</option>
                  </Form.Select>
                </div>
                <div className='col-md-12 mt-2'>
                  <div className='d-flex'><label>Matrix size</label></div>
                  <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter matrix size" onChange={(e) => setMatrixSize(e.target.value)} />
                </div>
                <div className="col-md-12 mt-2">
                  <label className='d-flex'>Type</label>
                  <Form.Select className="form-select" aria-label="Default select example" onChange={(e) => setType(e.target.value)} >
                    <option selected disabled>Select type</option>
                    <option value="Coil">Coil</option>
                    <option value="Packet">Packet</option>
                  </Form.Select>
                </div>
                <div className='col-md-12 mt-2'>
                  <div className='d-flex'><label>Quantity</label></div>
                  <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter quantity" onChange={(e) => setQuantity(e.target.value)} />
                </div>
              </div>

              {/* Rubber */}

              <div id="Rubber" style={{ display: 'none' }}>
                <div className="col-md-12 mt-2">
                  <label className='d-flex'>Quality</label>
                  <Form.Select class="form-select" aria-label="Default select example" onChange={(e) => setQuality(e.target.value)} >
                    <option selected disabled>Select Quality</option>
                    <option value="Green Rubber 35 Shore">Green Rubber 35 Shore</option>
                    <option value="Vulkolen Rubber">Vulkolen Rubber</option>
                    <option value="Grey Rubber">Grey Rubber</option>
                  </Form.Select>
                </div>
                <div className='col-md-12 mt-2'>
                  <div className='d-flex'><label>Rubber size</label></div>
                  <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter rubber size" onChange={(e) => setRubberSize(e.target.value)} />
                </div>
                <div className='col-md-12 mt-2'>
                  <div className='d-flex'><label>Quantity</label></div>
                  <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter quantity" onChange={(e) => setQuantity(e.target.value)} />
                </div>
              </div>
              <div className='col-md-12 mt-3 d-flex'>
                <Button onClick={handleBuyNow}>Order Now</Button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default Consumable;