import React from "react";
import Header from './header';
import team1 from '../assets/images/div.team-img.png';
import team2 from '../assets/images/div.team-img_2.png';
import { Link } from 'react-router-dom';
import team3 from '../assets/images/team_1_3.jpg.png';
import team4 from '../assets/images/div.team-img_3.png';


const AboutUs = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg sticky-top" id="my-navbar">
                <Header />
            </nav>
            <div className="container-fluid header_bg_img_about" style={{ marginTop: '-7rem' }}>
                <div className="container pb-5">
                    <div className="text-center" style={{ paddingTop: '12rem', paddingBottom: ' 4rem' }}>
                        <h1 className="fw-bold display-4 text-black">About Us</h1>
                        <div className="d-flex text-center align-items-center justify-content-center mt-3">
                            <h5 className="mx-2 fw-bold text-black">Home</h5>
                            <h5 className="mx-2 fw-bold text-black">About Us</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid our_team p-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 mb-lg-0 mb-4">
                            <div>
                                <img src="assets/images/about_2_1.png.png" alt="" className="w-100" />
                            </div>
                        </div>
                        <div className="col-md-8 mb-lg-0 mb-4">
                            <h5 className="d-flex text-danger text-uppercase">
                                <div className="mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path
                                            d="M14 4.4375C15.3462 4.4375 16.4375 3.34619 16.4375 2H17.5625C17.5625 3.34619 18.6538 4.4375 20 4.4375V5.5625C18.6538 5.5625 17.5625 6.65381 17.5625 8H16.4375C16.4375 6.65381 15.3462 5.5625 14 5.5625V4.4375ZM1 11C4.31371 11 7 8.31371 7 5H9C9 8.31371 11.6863 11 15 11V13C11.6863 13 9 15.6863 9 19H7C7 15.6863 4.31371 13 1 13V11ZM17.25 14C17.25 15.7949 15.7949 17.25 14 17.25V18.75C15.7949 18.75 17.25 20.2051 17.25 22H18.75C18.75 20.2051 20.2051 18.75 22 18.75V17.25C20.2051 17.25 18.75 15.7949 18.75 14H17.25Z"
                                            fill="#DB2C1D"></path>
                                    </svg>
                                </div>About Company
                            </h5>
                            <h1 className="text-black fw-bold">
                                About Our Company
                            </h1>
                            <p className="mb-0 text-black">
                                SHARP GRAPHS & GEARS is one of the established laser die maker founded in 1978
                                as a manual die maker and is located in Ludhiana City of Punjab. We are serving the industry
                                since 1978 & became the solution provider to industry & also strategically. growing with the
                                emerging market in India. We thank to all our clients for doing business with us. As a
                                quality minded
                                entity & with primarily focused on quality we are the first in Ludhiana City of Punjab to
                                introduce
                                laser dies by installing the latest machinery in Laser Cutting & Auto Rule Processor. We are
                                the first to
                                equip the world className State Of The Art. Technical Knownhow, Large Experience which enables
                                us to provide any Kind of solution
                                to the printing & packaging industry.
                            </p>
                            <p className="text-black">
                                SHARP GRAPHS & GEARS is one of the established laser die maker founded in 1978
                                as a manual die maker and is located in Ludhiana City of Punjab. We are serving the industry
                                since 1978 & became the solution provider to industry & also strategically. growing with the
                                emerging market in India. We thank to all our clients for doing business with us. As a
                                quality minded
                                entity & with primarily focused on quality we are the first in Ludhiana City of Punjab to
                                introduce
                                laser dies by installing the latest machinery in Laser Cutting & Auto Rule Processor. We are
                                the first to
                                equip the world className State Of The Art. Technical Knownhow, Large Experience which enables
                                us to provide any Kind of solution
                                to the printing & packaging industry.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-5 bg-danger">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-3 mb-lg-0 mb-4">
                            <div className="bg-black text-center p-5 rounded-circle">
                                <h1 className="text-light display-4 fw-bold py-4 text-uppercase">25</h1>
                                <p className="text-light">Years Experience</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-lg-0 mb-4">
                            <div className="text-center border border-dark border-2 p-5 rounded-circle">
                                <h1 className="text-black display-4 fw-bold py-4 text-uppercase">22K</h1>
                                <p className="text-black">Project's Complete</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-lg-0 mb-4">
                            <div className="text-center border border-dark border-2 p-5 rounded-circle">
                                <h1 className="text-black display-4 fw-bold py-4 text-uppercase">18K</h1>
                                <p className="text-black">Customer Happy</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-lg-0 mb-4">
                            <div className="text-center border border-dark border-2 p-5 rounded-circle">
                                <h1 className="text-black display-4 fw-bold py-4 text-uppercase">180</h1>
                                <p className="text-black">Winning Awards</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-5 our_team">
                <div className="container mt-5 pb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-lg-0 mb-4">
                            <h5 className="d-flex text-danger text-uppercase mb-5">
                                <div className="mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path
                                            d="M14 4.4375C15.3462 4.4375 16.4375 3.34619 16.4375 2H17.5625C17.5625 3.34619 18.6538 4.4375 20 4.4375V5.5625C18.6538 5.5625 17.5625 6.65381 17.5625 8H16.4375C16.4375 6.65381 15.3462 5.5625 14 5.5625V4.4375ZM1 11C4.31371 11 7 8.31371 7 5H9C9 8.31371 11.6863 11 15 11V13C11.6863 13 9 15.6863 9 19H7C7 15.6863 4.31371 13 1 13V11ZM17.25 14C17.25 15.7949 15.7949 17.25 14 17.25V18.75C15.7949 18.75 17.25 20.2051 17.25 22H18.75C18.75 20.2051 20.2051 18.75 22 18.75V17.25C20.2051 17.25 18.75 15.7949 18.75 14H17.25Z"
                                            fill="#DB2C1D"></path>
                                    </svg>
                                </div>OUR SKILLS
                            </h5>
                            <h1 className="text-black display-5 fw-bold">
                                We're Experts & Professional
                            </h1>
                            <p className="mt-4">
                                We Manufactures of all Kinds of Manual & Laser Dies For Board Boxes, Corrugated
                                Cartoon, Label, Cuff, Coller & Ladies Suit Gala Cutting, Weeding Cards & Boxes, Gaskets,
                                Blister Cutting, Tags Dies Etc. We manufactures these products incroporating genuine grade.
                            </p>
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="text-black">Service 1</p>
                                    <p className="text-black">70%</p>
                                </div>
                                <div className="progress mb-1" style={{ height: 5 }}>
                                    <div className="progress-bar bg-danger" role="progressbar" aria-label="Example 1px high"
                                        style={{ width: '50%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="progress" style={{ height: 5 }}>
                                    <div className="progress-bar bg-secondary" role="progressbar" aria-label="Example 1px high"
                                        style={{ width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex mt-3 justify-content-between align-items-center">
                                    <p className="text-black">Service 1</p>
                                    <p className="text-black">80%</p>
                                </div>
                                <div className="progress mb-1" style={{ height: 5 }}>
                                    <div className="progress-bar bg-danger" role="progressbar" aria-label="Example 1px high"
                                        style={{ width: '65%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="progress" style={{ height: 5 }}>
                                    <div className="progress-bar bg-secondary" role="progressbar" aria-label="Example 1px high"
                                        style={{ width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex mt-3 justify-content-between align-items-center">
                                    <p className="text-black">Service 1</p>
                                    <p className="text-black">85%</p>
                                </div>
                                <div className="progress mb-1" style={{ height: 5 }}>
                                    <div className="progress-bar bg-danger" role="progressbar" aria-label="Example 1px high"
                                        style={{ width: '75%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="progress" style={{ height: 5 }}>
                                    <div className="progress-bar bg-secondary" role="progressbar" aria-label="Example 1px high"
                                        style={{ width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-lg-0 mb-4">
                            <div>
                                <img src="assets/images/skil-thumb1.png.png" alt="" classNameName="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-danger p-4">
                <div className="hero-carousel owl-carousel owl-theme align-items-center">
                    <div className="item text-light text-center">
                        <h5>Logo</h5>
                    </div>
                    <div className="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path
                                d="M14 4.4375C15.3462 4.4375 16.4375 3.34619 16.4375 2H17.5625C17.5625 3.34619 18.6538 4.4375 20 4.4375V5.5625C18.6538 5.5625 17.5625 6.65381 17.5625 8H16.4375C16.4375 6.65381 15.3462 5.5625 14 5.5625V4.4375ZM1 11C4.31371 11 7 8.31371 7 5H9C9 8.31371 11.6863 11 15 11V13C11.6863 13 9 15.6863 9 19H7C7 15.6863 4.31371 13 1 13V11ZM17.25 14C17.25 15.7949 15.7949 17.25 14 17.25V18.75C15.7949 18.75 17.25 20.2051 17.25 22H18.75C18.75 20.2051 20.2051 18.75 22 18.75V17.25C20.2051 17.25 18.75 15.7949 18.75 14H17.25Z"
                                fill="rgba(248,242,242,1)"></path>
                        </svg>
                    </div>
                    <div className="item text-light text-center">
                        <h5>Logo</h5>
                    </div>
                    <div className="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path
                                d="M14 4.4375C15.3462 4.4375 16.4375 3.34619 16.4375 2H17.5625C17.5625 3.34619 18.6538 4.4375 20 4.4375V5.5625C18.6538 5.5625 17.5625 6.65381 17.5625 8H16.4375C16.4375 6.65381 15.3462 5.5625 14 5.5625V4.4375ZM1 11C4.31371 11 7 8.31371 7 5H9C9 8.31371 11.6863 11 15 11V13C11.6863 13 9 15.6863 9 19H7C7 15.6863 4.31371 13 1 13V11ZM17.25 14C17.25 15.7949 15.7949 17.25 14 17.25V18.75C15.7949 18.75 17.25 20.2051 17.25 22H18.75C18.75 20.2051 20.2051 18.75 22 18.75V17.25C20.2051 17.25 18.75 15.7949 18.75 14H17.25Z"
                                fill="rgba(248,242,242,1)"></path>
                        </svg>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-5 our_team">
                <div className="container text-center">
                    <p className="text-danger fw-bold">OUR TEAM</p>
                    <h1>Our Experience Team</h1>
                    <div className="row mt-4">
                        <div className="col-md-3 mb-lg-0 mb-4">
                        <div >
                            <div className="card bg-transparent border-0">
                                <img src={team1} alt="" className="w-100 position-relative"style={{width:300,height:350}} />
                                <div className="position-absolute bg-danger text-light our_team_card">
                                    <p className="plus_icon"><i className="ri-add-fill fs-4"></i></p>
                                </div>
                              
                               <div className="card-body text-start">
                                    <h4 className="fw-bold">Mr. ROSHAN LAL</h4>
                                    <p>
                                        Started business in 1978
                                        Experience of 41 Years

                                    </p>

                                </div>
                               </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-lg-0 mb-4">
                        <div >
                            <div className="card bg-transparent border-0">
                                <img src={team2} alt="" className="w-100 position-relative "style={{width:300,height:350}} />
                                <div className="position-absolute bg-danger text-light our_team_card">
                                    <p className="plus_icon"><i className="ri-add-fill fs-4"></i></p>
                                </div>
                                <div className="card-body text-start">
                                    <h4 className="fw-bold">Mr.AJAY KUMAR</h4>
                                    <p>
                                        Joined business in 1993
                                        Experience of 26 Years
                                    </p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-lg-0 mb-4">
                            <div className="card bg-transparent border-0">
                                <img src={team3} alt="" className="w-100 position-relative" style={{width:300,height:350}}/>
                                <div className="position-absolute bg-danger text-light our_team_card ">
                                    <p className="plus_icon"><i className="ri-add-fill fs-4"></i></p>
                                </div>
                                <div className="card-body text-start">
                                    <h4 className="fw-bold">Mr. RAVI KUMAR</h4>

                                    <p>
                                        Joined business in 2001
                                        Experience of 18 Years
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-lg-0 mb-4">
                            <div className="card bg-transparent border-0">
                                <img src={team4} alt="" className="w-100 position-relative "style={{width:300,height:350}} />
                                <div className="position-absolute bg-danger text-light our_team_card">
                                    <p className="plus_icon"><i className="ri-add-fill fs-4"></i></p>
                                </div>
                                <div className="card-body text-start">
                                    <h4 className="fw-bold">Mr. KRISHAN KUMAR</h4>
                                    <p>
                                        Joined business in 2004
                                        Experience of 15 Years
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-5 bg-black">
                <div className="container">
                    <div className="row align-items-center text-light border-bottom pb-4 border-secondary">
                        <div className="col-md-9">
                            <h1>Want to Know more about us?</h1>
                        </div>
                        <div className="col-md-3">

                        </div>
                    </div>
                    <div className="row mt-5 border-bottom pb-5 border-secondary">
                        <div className="col-md-4 mb-lg-0 mb-4">
                            <div>
                                <h4 className="text-light fw-bold mb-4">SHARP GRAPHS AND GEARS</h4>
                                <p className="mt-3 text-light">
                                    SHARP GRAPHS & GEARS is one of the established laser die maker founded in 1978 as a manual die maker and is located
                                    in Ludhiana City of Punjab. We are serving the industry since 1978 & became the
                                    solution provider to industry & also strategically growing with the emerging market in India.
                                    We thank to all our clients for doing business with us. As a quality minded entity & with primarily focused
                                    on quality
                                </p>
                                <p className="mt-4">
                                    <i className="ri-facebook-fill mx-2 rounded-circle p-2 text-light bg-danger"></i>
                                    <i className="ri-twitter-fill mx-2 border rounded-circle p-2 text-light"></i>
                                    <i className="ri-whatsapp-fill mx-2 border rounded-circle p-2 text-light"></i>
                                    <i className="ri-linkedin-box-fill mx-2 border rounded-circle p-2 text-light"></i>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-lg-0 mb-4">
                            <div className="text-light">
                                <h4 className="mb-4">Explore</h4>
                                <p>Home</p>
                                <p>About Us</p>
                                <p>Contact Us</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-lg-0 mb-4">
                            <div className="text-light">
                                <h4 className="mb-4">Get In touch</h4>
                                <p>
                                    B- XXX , 3959 / 1 , St. No. 2 Hargobind Pura , Near Samrala Byepass , Ludhiana - 141010 , Punjab , India
                                </p>
                                <a href="#" className="text-decoration-none text-light">sharp_graphgears@yahoo.com</a>

                                <p className="mt-2"><a href="#" className="text-decoration-none text-light">roshandiemaker@gmail.com</a></p>

                                <p>
                                    +91 0161 260 5761
                                </p>
                                <p>
                                    +91 0161 505 4318
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-5 text-light">
                        <div>
                            <p>
                                Copyright &copy;2023 Engview. All Rights Reserved.
                            </p>
                        </div>
                        <div>
                            <p>Privacy Policy &#x2022; Terms & Consition</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutUs