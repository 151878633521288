import React from "react";
import AgentHeader from "./common/agentheader";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Wallet from "./wallet";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assect/css/custom.css';
import WithdrawalReport from "./withdrawalreport";
import OrderReport from "./orderreport";

const Dashboard = () => {
    return (
        <div id="theme-default" className="full_block">
            <AgentHeader />
            <div id="content">
                <div className="fullbody">
                    <div className="buttondiv">
                        <Tabs
                            defaultActiveKey="wallet"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{ fontSize: '20px' }}
                        >
                            <Tab eventKey="wallet" title="Wallet">
                                <Wallet />
                            </Tab>
                            <Tab eventKey="withdrawal" title="Withdrawal Report">
                                <WithdrawalReport />
                            </Tab>
                            <Tab eventKey="orderreport" title="Order Report">
                                <OrderReport />
                            </Tab>
                        </Tabs>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Dashboard;