import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assect/css/custom.css';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../../config/lib";
import Loading from '../../common/Loading';

const Graph = () => {
  const [totalorders, setTotalOrders] = useState();
  const [comporders, setCompOrders] = useState();
  const [pending, setPending] = useState();
  const [quat, setQuat] = useState();
  const [keyline, setKeyLine] = useState();
  const [loading, setLoading] = useState(false);

  const data = [
    { name: 'Total Number Of Orders', students: parseInt(totalorders) },
    { name: 'Total Number of Sample', students: parseInt(pending) },
    { name: 'Total Quotation', students: parseInt(quat) },
    { name: 'Total Key Line', students: parseInt(keyline) },
  ];

  useEffect(() => {
    handleTotalOrders();
    handleQuations();
    handleKeyLines();
    setLoading(false);
  }, []);

  const handleTotalOrders = async () => {

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('apikey', token);
    setLoading(true);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.totalorders, formData);
      setTotalOrders(response.data.order);
      setLoading(false);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
      setLoading(false);
    }
  }

  const handleQuations = async () => {

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('apikey', token);
    setLoading(true);
    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.totalsample, formData);
      setQuat(response.data.order);
      setLoading(false);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
      setLoading(false);
    }
  }

  const handleKeyLines = async () => {

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('apikey', token);
    setLoading(true);
    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.totalkeyline, formData);
      setKeyLine(response.data.order);
      setLoading(false);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
      setLoading(false);
    }
  }

  const handleTotalquation = async () => {

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('apikey', token);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.totalquatation, formData);
      setKeyLine(response.data.order);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
    }
  }

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div>
      <div className=" grid_container" style={{ width: '100%' }}>
        <Loading loading={loading} />
        <div className='grid_6'>
          <div className="widget_wrap" style={{ height: '420px' }}>
            <div className="widget_top">
              <span className="h_icon list"></span>
              <h6>Overview Chart</h6>
            </div>
            <div class="widget_content">
              <center>
                <div className="pie-chart-container">
                  <PieChart width={680} height={350}>
                    <Pie
                      data={data}
                      dataKey="students"
                      outerRadius={170}
                      fill="#8884d8"
                      label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
                        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                        const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
                        const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
                        return (
                          <text
                            x={x}
                            y={y}
                            fill="white"
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline="central"
                          >
                            {`${(percent * 100).toFixed(0)}%`}
                          </text>
                        );
                      }}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend align="left" verticalAlign="middle" layout="vertical" iconSize={10} />
                    <Tooltip />
                  </PieChart>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;

