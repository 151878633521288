import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import Header from "./common/header";
import TopMenu from "./common/topmenu";
import { Component, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from 'axios';
import lib from "../config/lib";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InvoiveModal from './invoicepayment/InvoiveModal'
import Loading from "../common/Loading";

const PaymentHistory = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const itemsPerPage = 10;
	const [data, setData] = useState([]);
	const [wallet, setWallet] = useState('');
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const [hideshow, setHideshow] = useState('');

	const handleSearch = async () => {
		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		formData.append('type', 'payment');
		formData.append('search', search);
		setLoading(true);

		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.orderserch, formData);
			setData(response.data);
			setLoading(false);
		}
		catch (error) {
			console.log(error);
			setLoading(false);
		}
	}

	useEffect(() => {
		handlePayment();
		handleWalletbalance();
	}, []);

	const handlePayment = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);

		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.paymenthistory, formData);
			setData(response.data);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleWalletbalance = async () => {
		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.profile, formData);
			setWallet(response.data.wallet);
			setHideshow(response.data.hideshow);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	// Calculate the total number of pages
	const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

	// Function to handle page change
	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};

	// Function to get the current page's data
	const getCurrentPageData = () => {
		const startIndex = currentPage * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return data ? data.slice(startIndex, endIndex) : [];
	};

	const currentPageData = getCurrentPageData([]);

	return (
		<div>
			<Header />
			<TopMenu />
			<div id="theme-default" className="full_block">
				<Loading loading={loading} />
				<Tabs
					defaultActiveKey="Payments history"
					transition={false}
					id="noanim-tab-example"
					className="mb-3 mt-3"
					style={{ fontSize: '20px' }}
				>
					<Tab eventKey="Payments history" title="Payments history" >
						<div class="widget_wrap">
							<div className="row align-items-center">
								<div className="col-lg-3 d-flex">
									<input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setSearch(e.target.value)} />
									<Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
								</div>
								<div className="col-lg-2 ms-lg-auto">
									<div>Total Balance</div>
									<div className="mt-2"><span className="outstanding-text">{wallet}</span></div>
								</div>
							</div>
							<div class="widget_top">
								<span class="h_icon blocks_images"></span>
								<h6>Full Orders Report </h6>
							</div>

							<div class="widget_content">
								<table class="display data_tbl">
									<thead>
										<tr>
											<th>Id</th>
											<th>Order Type</th>
											<th>Job number</th>
											<th>Amount</th>
											<th>GST</th>
											<th>Amount With tax</th>
											<th>Date</th>
											<th>Trans Type</th>
											<th>Status</th>
											{hideshow === 'Yes' ? (
												<th>View invoice</th>
											) : (
												<></>
											)}

										</tr>
									</thead>
									<tbody>
										{currentPageData && currentPageData.map((item) => (
											<tr key={item.id}>
												<td>{item.id}</td>
												<td>{item.ordertype}</td>
												<td>{item.orderid}</td>
												<td>{item.amount}</td>
												<td>18%</td>
												<td>{item.amount*18/100+parseFloat(item.amount)}</td>
												<td>{item.date}</td>
												<td>{item.transtype}</td>
												<td>
													{item.status === 'approve' ? (
														<span className="badge_style b_done">{item.status}</span>
													) : (
														<span className="badge_style b_pending">{item.status}</span>
													)}
												</td>
												<td>
													{hideshow === 'Yes' ? (
														<InvoiveModal jobno={item.orderid} />
													) : (
														<></>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
								<hr />

								<ReactPaginate
									previousLabel={"Previous"}
									nextLabel={"Next"}
									breakLabel={"..."}
									pageCount={pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={10}
									onPageChange={handlePageChange}
									containerClassName={"pagination"}
									subContainerClassName={"pages pagination"}
									activeClassName={"active"}
								/>

							</div>
						</div>
					</Tab>
				</Tabs>
			</div>
		</div>
	)
}
export default PaymentHistory;