import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import Header from "./common/header";
import TopMenu from "./common/topmenu";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MyOrder from "./completedorders";
import PendingOrders from './pendingorders';
import TableQutation from './tablequtation';
import ConsumableTable from './ConsumableTable'
import CustomOrderTable from './CustomOrderTable'
import KeylineTable from './KeylineTable'
import QuationTable from './QuationTable'
import StandardTable from './StandardTable'
import SampleTable from "./SamleTable";
import KeylinesNewTable from "./KeylinesNewTable";

const MyOrderFilter = () => {
    return (
        <div id="theme-default" className="full_block">
            <Header />
            <TopMenu />
            <div id="content">
                <div className="fullbody">
                    <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                        <Tabs
                            defaultActiveKey="Standard"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{ fontSize: '20px' }}
                        >
                            <Tab eventKey="Standard" title="Standard">
                                <StandardTable />
                            </Tab>
                            <Tab eventKey="Custom Order" title="New Order">
                                <CustomOrderTable />
                            </Tab>
                            <Tab eventKey="printmachine" title="Rule Change">
                                <KeylineTable />
                            </Tab>
                            <Tab eventKey="Consumable" title="Consumable">
                                <ConsumableTable />
                            </Tab>
                            <Tab eventKey="diemachine" title="Quotation">
                                <QuationTable />
                            </Tab>
                            <Tab eventKey="Keyline" title="Keyline">
                                <KeylinesNewTable />
                            </Tab>
                            <Tab eventKey="Sample" title="Sample">
                                <SampleTable />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MyOrderFilter;