import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import lib from '../config/lib';
import { FaRegEdit } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { LuEye } from "react-icons/lu";
import Description from '../DescriptionModal';
import Loading from '../common/Loading';
import DeleteJob from '../DeleteButton';

function SampleTable() {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');

    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [shows, setShows] = useState(false);

    const [jobnumberdescription, setJobnumberdescription] = useState('');

    const handleCloses = () => setShows(false);
    const handleShows = (id) => {
        setShows(true);
        setJobnumberdescription(id);
    }



    useEffect(() => {
        handleSample();
    }, []);

    const handleSample = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.sampleordertable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleSearch = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'keyline');
        formData.append('search', search);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.orderserch, formData);
            setData(response.data);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleFilter = async (e) => {
        var filter = e.target.value;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', filter);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.keylinetable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Modal
    const [jobno, setJobnumber] = useState('');
    const [cat, setCat] = useState('');
    const [boxstyle, setBoxstyle] = useState('');
    const [sheetsize, setSheetsize] = useState('');
    const [productimage, setProductImage] = useState('');
    const [lenth, setLenth] = useState('');
    const [width, setWidth] = useState('');
    const [height, setheight] = useState('');
    const [date, setDate] = useState('');
    const [status, setStatus] = useState('');
    const [sid, setSid] = useState('');

    const [designerimg, setDesignerImg] = useState('');

    useEffect(() => {
        handleKeylineFeth();
    }, []);

    const handleKeylineFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        // formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.sampleordertable, formData);
            if (response.data.status === "success") {
                setJobnumber(response.data.jobno);
                setCat(response.data.cat);
                setBoxstyle(response.data.boxstyle);
                setSheetsize(response.data.sheetsize);
                setProductImage(response.data.productimg);
                setLenth(response.data.lenth);
                setWidth(response.data.width);
                setheight(response.data.height);
                setDate(response.data.date);
                setStatus(response.data.tracker);
                setSid(response.data.sid);
                handleDesignerimg();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleDesignerimg = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        // formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.designerimg, formData);
            if (response.data.status == "success") {
                setDesignerImg(response.data.designerimg);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row align-items-center">
                <Loading loading={loading} />
                <div className="col-lg-3 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setSearch(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div className="col-lg-2 ms-lg-auto">
                    <div className="me-2">
                        <Form.Select aria-label="Default select example" onChange={handleFilter}>
                            <option disabled>Select filter</option>
                            <option value="All">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Complete</option>
                        </Form.Select>
                    </div>
                </div>
            </div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Job Number</th>
                        <th>Job Name</th>
                        <th>Category</th>
                        <th>Box Style</th>
                        <th>Sheet Size</th>
                        <th>Lenth</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Product Image</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData && currentPageData.map((item) => (
                        <tr>
                            <td>{item.orderid}</td>
                            <td>{item.jobname}</td>
                            <td>{item.cat}</td>
                            <td>{item.boxstyle}</td>
                            <td>{item.sheetsize}</td>
                            <td>{item.lenth}</td>
                            <td>{item.width}</td>
                            <td>{item.height}</td>
                            <td><a href={lib.imgurl + '/' + item.productimg} target="_blank" >View Image</a></td>
                            <td>{item.date}</td>
                            <td>{item.status}</td>
                            <td className='d-flex'>
                                <LuEye onClick={() => handleShow(item.orderid)} size={20} />
                                {/* <FaRegEdit onClick={() => handleShows(item.orderid)} size={20} className='ms-3' /> */}
                                <DeleteJob handleJobnumber={item.orderid} handleJobfrom={"keyline"}/>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sample</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>

                        <div className='col-md-12 d-flex justify-content-center mb-3' >
                            <span className=' invoce-tittle'>View Job Card</span>
                        </div>
                        {/* <div className='col-md-4'>
                            <span style={{ fontSize: 15 }}>Customer Image</span><span className='ms-2'><a href={lib.imgUrl + '/' + productimage} target="_blank" >View Image</a></span>
                        </div>

                        <div className='col-md-4'>
                            <span style={{ fontSize: 15 }}>Designer Image</span><span className='ms-2'><a href={lib.imgUrl + '/' + designerimg} target="_blank" >View Image</a></span>
                        </div> */}

                        <div className='col-md-6 mt-2'>
                            <label>Job no</label>
                            <input className='form-control' placeholder='Job no' value={jobno} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Customer code</label>
                            <input className='form-control' placeholder='customer code' value='8465454' />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Category</label>
                            <input className='form-control' placeholder='Category' value={cat} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Box style</label>
                            <input className='form-control' placeholder='Box style' value={boxstyle} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Sheet size</label>
                            <input className='form-control' placeholder='Sheet size' value={sheetsize} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Length</label>
                            <input className='form-control' placeholder='Length' value={lenth} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Width</label>
                            <input className='form-control' placeholder='Width' value={width} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Height</label>
                            <input className='form-control' placeholder='Height' value={height} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Date</label>
                            <input className='form-control' placeholder='Date' value={date} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Status</label>
                            <input className='form-control' placeholder='Status' value={status} />
                        </div>

                        {/* <div className='col-md-6 mt-2 d-flex align-items-center'>
                            <span style={{ fontSize: 15 }}>Priting Machine Data</span><span className='ms-2'><DuplexSelected handleSendsid={handleSendsid} /></span>
                        </div>
                        <div className='col-md-6 mt-2 d-flex align-items-center'>
                            <span style={{ fontSize: 15 }}>Die Machine Data</span><span className='ms-2'><DuplexSelect handleSendsid={handleSendsid} /></span>
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Description */}

            <Modal show={shows} onHide={handleCloses} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Description jobnumberdescription={jobnumberdescription} handleJobfrom={'keyline'} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloses}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default SampleTable    