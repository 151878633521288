import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assect/css/custom.css';
import { Component, useState } from "react";
import ReactPaginate from "react-paginate";
import AgentHeader from "./common/agentheader";
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../../config/lib";
import Loading from "../../common/Loading";

const WithdrawalReport = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const itemsPerPage = 10;
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		handleOrder();
	}, []);

	const handleOrder = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);

		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.WithdrawalReport, formData);
			setData(response.data);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);

		}
	}


	// Calculate the total number of pages
	const pageCount = Math.ceil(data.length / itemsPerPage);

	// Function to handle page change
	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};

	// Function to get the current page's data
	const getCurrentPageData = () => {
		const startIndex = currentPage * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return data.slice(startIndex, endIndex);
	};

	const currentPageData = getCurrentPageData();

	return (
		<div>
			<AgentHeader />
			<div id="theme-default" className="full_block">
				<Loading loading={loading} />
				<div class="widget_wrap">
					<div class="widget_top">
						<span class="h_icon blocks_images"></span>
						<h6>Withdrawal Report </h6>
					</div>

					<div class="widget_content">
						<table class="display data_tbl">
							<thead>
								<tr>
									<th>Id</th>
									<th>Acc No</th>
									<th>IFSC Code</th>
									<th>Bank Name</th>
									<th>Amount</th>
									<th>Date</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{currentPageData.map((item) => (
									<tr key={item.id}>
										<td>{item.id}</td>
										<td>{item.accno}</td>
										<td>{item.ifsc}</td>
										<td>{item.bankname}</td>
										<td>{item.amount}</td>
										<td>{item.date}</td>
										<td>
											{item.status === 'Publish' ? (
												<span className="badge_style b_done">{item.status}</span>
											) : (
												<span className="badge_style b_pending">{item.status}</span>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<hr />

						<ReactPaginate
							previousLabel={"Previous"}
							nextLabel={"Next"}
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={handlePageChange}
							containerClassName={"pagination"}
							subContainerClassName={"pages pagination"}
							activeClassName={"active"}
						/>

					</div>
				</div>
			</div>
		</div>
	)
}
export default WithdrawalReport;