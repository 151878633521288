import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import lib from '../config/lib';
import Modal from 'react-bootstrap/Modal';
import { LuEye } from "react-icons/lu";
import { FaRegEdit } from "react-icons/fa";
import { Accordion } from 'react-bootstrap';
import Description from '../DescriptionModal';
import Loading from '../common/Loading';
import DeleteJob from '../DeleteButton';

function PaymentHistory() {
    const [maincat, setmaincat] = useState('');
    const [lenth, setlenth] = useState('');
    const [width, setwidth] = useState('');
    const [height, setheight] = useState('');
    const [dbtf, setdbtf] = useState('');
    const [dbgf, setdbgf] = useState('');
    const [dbdf, setdbdf] = useState('');
    const [dbmargin, setdbmargin] = useState('');
    const [dblock, setdblock] = useState('');
    const [dbIf, setdbIf] = useState('');
    const [dbwall, setdbwall] = useState('');
    const [dbfg, setdbfg] = useState('');
    const [dbIn, setdbIn] = useState('');
    const [dbinl, setdbinl] = useState('');
    const [dbft, setdbft] = useState('');
    const [dbfl, setdbfl] = useState('');
    const [dblp, setdblp] = useState('');
    const [dbdh, setdbdh] = useState('');
    const [dbM, setdbM] = useState('');
    const [dbdfh, setdbdfh] = useState('');
    const [dbdfd, setdbdfd] = useState('');
    const [dbll, setdbll] = useState('');
    const [dblu, setdblu] = useState('');
    const [dblex, setdblex] = useState('');
    const [dbtarc, setdbtarc] = useState('');
    const [dbarcd, setdbarcd] = useState('');
    const [dbhc, setdbhc] = useState('');
    const [dbhf, setdbhf] = useState('');
    const [dbhcl, setdbhcl] = useState('');
    const [dbhft, setdbhft] = useState('');
    const [dbha, setdbha] = useState('');
    const [dbhadf, setdbhadf] = useState('');
    const [dbslt, setdbslt] = useState('');
    const [dbIflp, setdbIflp] = useState('');
    const [dbsl, setdbsl] = useState('');
    const [dbsh, setdbsh] = useState('');
    const [dbhst, setdbhst] = useState('');
    const [dbifl, setdbifl] = useState('');
    const [dblks, setdblks] = useState('');
    const [dblkr, setdblkr] = useState('');
    const [dbmgn, setdbmgn] = useState('');
    const [dbtmgn, setdbtmgn] = useState('');
    const [dbfmgn, setdbfmgn] = useState('');
    const [dbIfld, setdbIfld] = useState('');
    const [dbsd, setdbsd] = useState('');
    const [dbifr, setdbifr] = useState('');
    const [dbthsb, setdbthsb] = useState('');
    const [dbthbh, setdbthbh] = useState('');
    const [dbkanr, setdbkanr] = useState('');
    const [dbslth2, setdbslth2] = useState('');
    const [dbiff, setdbiff] = useState('');
    const [dbins, setdbins] = useState('');
    const [dbilk, setdbilk] = useState('');
    const [dbfif, setdbfif] = useState('');
    const [dbcl, setdbcl] = useState('');
    const [dbgrip, setdbgrip] = useState('');
    const [dbgcons, setdbgcons] = useState('');
    const [dbsm, setdbsm] = useState('');
    const [dbtp, setdbtp] = useState('');
    const [dblard, setdblard] = useState('');
    const [dbhd, setdbhd] = useState('');

    const [handleOrderidsend, sethandleOrderidsend] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleStanderddisplayfeth();
    }, []);

    const handleStanderddisplayfeth = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerddatadisplayfeth, formData);
            setmaincat(response.data.maincat);
            setlenth(response.data.lenth);
            setwidth(response.data.width);
            setheight(response.data.height);
            setdbtf(response.data.dbtf);
            setdbgf(response.data.dbgf);
            setdbdf(response.data.dbdf);
            setdbmargin(response.data.dbmargin);
            setdblock(response.data.dblock);
            setdbIf(response.data.dbIf);
            setdbwall(response.data.dbwall);
            setdbfg(response.data.dbfg);
            setdbIn(response.data.dbIn);
            setdbinl(response.data.dbinl);
            setdbft(response.data.dbft);
            setdbfl(response.data.dbfl);
            setdblp(response.data.dblp);
            setdbdh(response.data.dbdh);
            setdbM(response.data.dbM);
            setdbdfh(response.data.dbdfh);
            setdbdfd(response.data.dbdfd);
            setdbll(response.data.dbll);
            setdblu(response.data.dblu);
            setdblex(response.data.dblex);
            setdbtarc(response.data.dbtarc);
            setdbarcd(response.data.dbarcd);
            setdbhc(response.data.dbhc);
            setdbhf(response.data.dbhf);
            setdbhcl(response.data.dbhcl);
            setdbhft(response.data.dbhft);
            setdbha(response.data.dbha);
            setdbhadf(response.data.dbhadf);
            setdbslt(response.data.dbslt);
            setdbIflp(response.data.dbIflp);
            setdbsl(response.data.dbsl);
            setdbsh(response.data.dbsh);
            setdbhst(response.data.dbhst);
            setdbifl(response.data.dbifl);
            setdblks(response.data.dblks);
            setdblkr(response.data.dblkr);
            setdbmgn(response.data.dbmgn);
            setdbtmgn(response.data.dbtmgn);
            setdbfmgn(response.data.dbfmgn);
            setdbIfld(response.data.dbIfld);
            setdbsd(response.data.dbsd);
            setdbifr(response.data.dbifr);
            setdbthsb(response.data.dbthsb);
            setdbthbh(response.data.dbthbh);
            setdbkanr(response.data.dbkanr);
            setdbslth2(response.data.dbslth2);
            setdbiff(response.data.dbiff);
            setdbins(response.data.dbins);
            setdbilk(response.data.dbilk);
            setdbfif(response.data.dbfif);
            setdbcl(response.data.dbcl);
            setdbgrip(response.data.dbgrip);
            setdbgcons(response.data.dbgcons);
            setdbsm(response.data.dbsm);
            setdbtp(response.data.dbtp);
            setdblard(response.data.dblard);
            setdbhd(response.data.dbhd);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true)
        sethandleOrderidsend(id)
    };

    const [shows, setShows] = useState(false);
    const [jobnumberdescription, setJobnumberdescription] = useState('');

    const handleCloses = () => setShows(false);
    const handleShows = (id) => {
        setShows(true);
        setJobnumberdescription(id);
    }


    useEffect(() => {
        handleSample();
    }, []);

    const handleSample = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerdstable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleSearch = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'standerd');
        formData.append('search', search);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.orderserch, formData);
            setData(response.data);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleFilter = async (e) => {
        var filter = e.target.value;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', filter);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerdstable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row align-items-center">
                <Loading loading={loading} />
                <div className="col-lg-3 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setSearch(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div className="col-lg-2 ms-lg-auto">
                    <div className="me-2">
                        <Form.Select aria-label="Default select example" onChange={handleFilter}>
                            <option disabled>Select filter</option>
                            <option value="All">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Complete</option>
                        </Form.Select>
                    </div>
                </div>
            </div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Job No</th>
                        <th>Job Name</th>
                        <th>Product Image</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData && currentPageData.map((item) => (
                        <tr>
                            <td>{item.orderid}</td>
                            <td>{item.jobname}</td>
                            <td><a href={lib.imgurl + '/' + item.productimg} target="_blank">View Image</a></td>
                            <td>{item.date}</td>
                            <td>{item.status}</td>
                            <td className='d-flex'>
                                <LuEye onClick={() => handleShow(item.orderid)} size={20} />
                                <DeleteJob handleJobnumber={item.orderid} handleJobfrom={"standerdorder"}/>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />

            <Modal show={show} onHide={handleClose} centered size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Standard View</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-md-4 mt-2 '>
                            <label className='pb-0'>Material</label>
                            <input type="text" id="input-name" placeholder='Duplex or corrugrated' value={maincat} disabled />
                        </div>
                        <div className='col-md-4 mt-2 '>
                            <label className='pb-0'>Quality</label>
                            <input type="text" id="input-name" placeholder='Customer data' value={''} disabled />
                        </div>
                        {/* <div className='col-md-4 mt-2 '>
                            <label className='pb-0'>Sheet thikness</label>
                            <input type="text" id="input-name" placeholder='Sheet thikness' value={'Sheet thikness'} disabled />
                        </div> */}
                        <div className='col-md-4 mt-2 '>
                            <label className='pb-0'>Sheet thikness</label>
                            <Form.Select aria-label="Default select example" >
                                <option disabled selected>Select Sheet thikness</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </Form.Select>
                        </div>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Main Pannel</Accordion.Header>
                                <Accordion.Body>

                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <h5><span>Length</span><span className='ms-2 text-danger'>{lenth}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>width</span><span className='ms-2 text-danger'>{width}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Height</span><span className='ms-2 text-danger'>{height}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Tf</span><span className='ms-2 text-danger'>{dbtf}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Gf</span><span className='ms-2 text-danger'>{dbgf}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Df</span><span className='ms-2 text-danger'>{dbdf}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Margin</span><span className='ms-2 text-danger'>{dbmargin}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Lock</span><span className='ms-2 text-danger'>{dblock}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>If</span><span className='ms-2 text-danger'>{dbIf}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Wall</span><span className='ms-2 text-danger'>{dbwall}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Ftg</span><span className='ms-2 text-danger'>{dbfg}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>In</span><span className='ms-2 text-danger'>{dbIn}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Inl</span><span className='ms-2 text-danger'>{dbinl}</span></h5>
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2nd Pannel</Accordion.Header>
                                <Accordion.Body>

                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <h5><span>Ft</span><span className='ms-2 text-danger'>{dbft}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Fl</span><span className='ms-2 text-danger'>{dbfl}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Lp</span><span className='ms-2 text-danger'>{dblp}</span></h5>
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>3rd Pannel</Accordion.Header>
                                <Accordion.Body>

                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <h5><span>Dh</span><span className='ms-2 text-danger'>{dbdh}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>M</span><span className='ms-2 text-danger'>{dbM}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Dfh</span><span className='ms-2 text-danger'>{dbdfh}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Dfd</span><span className='ms-2 text-danger'>{dbdfd}</span></h5>
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>4rth Pannel</Accordion.Header>
                                <Accordion.Body>

                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <h5><span>Ll</span><span className='ms-2 text-danger'>{dbll}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Lu</span><span className='ms-2 text-danger'>{dblu}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Lex</span><span className='ms-2 text-danger'>{dblu}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Tarc</span><span className='ms-2 text-danger'>{dblex}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Lard</span><span className='ms-2 text-danger'>{dbtarc}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Arcd</span><span className='ms-2 text-danger'>{dbarcd}</span></h5>
                                        </div>

                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Holder Pannel</Accordion.Header>
                                <Accordion.Body>

                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <h5><span>Hd</span><span className='ms-2 text-danger'>{dbhd}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Hc</span><span className='ms-2 text-danger'>{dbhc}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Hf</span><span className='ms-2 text-danger'>{dbhf}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Hcl</span><span className='ms-2 text-danger'>{dbhcl}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Hft</span><span className='ms-2 text-danger'>{dbhft}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Ha</span><span className='ms-2 text-danger'>{dbha}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Hadf</span><span className='ms-2 text-danger'>{dbhadf}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Hadf</span><span className='ms-2 text-danger'>{dbhadf}</span></h5>
                                        </div>

                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Other Variable</Accordion.Header>
                                <Accordion.Body>

                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <h5><span>M</span><span className='ms-2 text-danger'>{dbM}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Slt</span><span className='ms-2 text-danger'>{dbslt}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Iflp</span><span className='ms-2 text-danger'>{dbIflp}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Sl</span><span className='ms-2 text-danger'>{dbsl}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Sh</span><span className='ms-2 text-danger'>{dbsh}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Hst</span><span className='ms-2 text-danger'>{dbhst}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>If1</span><span className='ms-2 text-danger'>{dbifl}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Fl</span><span className='ms-2 text-danger'>{dbfl}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Lks</span><span className='ms-2 text-danger'>{dblks}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Lkr</span><span className='ms-2 text-danger'>{dblkr}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Mgn</span><span className='ms-2 text-danger'>{dbmgn}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Tmgn</span><span className='ms-2 text-danger'>{dbtmgn}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Ifld</span><span className='ms-2 text-danger'>{dbIfld}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Sd</span><span className='ms-2 text-danger'>{dbsd}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>If</span><span className='ms-2 text-danger'>{dbIf}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Ifr</span><span className='ms-2 text-danger'>{dbifr}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Thsb</span><span className='ms-2 text-danger'>{dbthsb}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Thbh</span><span className='ms-2 text-danger'>{dbthbh}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Kanr</span><span className='ms-2 text-danger'>{dbkanr}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Slth2</span><span className='ms-2 text-danger'>{dbslth2}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Iff</span><span className='ms-2 text-danger'>{dbiff}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Ins</span><span className='ms-2 text-danger'>{dbins}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Ifl</span><span className='ms-2 text-danger'>{dbifl}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Ilk</span><span className='ms-2 text-danger'>{dbilk}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Fif</span><span className='ms-2 text-danger'>{dbfif}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Cl</span><span className='ms-2 text-danger'>{dbcl}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Grip</span><span className='ms-2 text-danger'>{dbgrip}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Gcons</span><span className='ms-2 text-danger'>{dbgcons}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Margin</span><span className='ms-2 text-danger'>{dbmargin}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Sm</span><span className='ms-2 text-danger'>{dbsm}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Sd</span><span className='ms-2 text-danger'>{dbsd}</span></h5>
                                        </div>
                                        <div className='col-md-2'>
                                            <h5><span>Tp</span><span className='ms-2 text-danger'>{dbtp}</span></h5>
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <Button className='btn-primary' onClick={handleStanderddisplayfeth}>Feth Data</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Description */}

            <Modal show={shows} onHide={handleCloses} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className='row p-2'>
                        <div className='col-lg-12'>
                            <label>Write The Things To Be Eidited</label>
                            <textarea rows={10} placeholder='Enter message...' onChange={(e) => setEiditdescription(e.target.value)}></textarea>
                        </div>
                        <div className='col-lg-12'>
                            <Button onClick={handleDescription} >Send</Button>
                        </div>
                    </div> */}
                    <Description jobnumberdescription={jobnumberdescription} handleJobfrom={'standerdorder'} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloses}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default PaymentHistory    