import React, { useState } from "react";
import Header from "./header";
import axios from 'axios';
import lib from '../config/lib';
import Swal from "sweetalert2";
import Loading from "../common/Loading";

const ContactUs = () => {
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');

    const [loading, setLoading] = useState(false);

    const handleMessage = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData();
        formData.append('fullname', fullname);
        formData.append('email', email);
        formData.append('description', description);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.messageindex, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Registered successfully successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg sticky-top" id="my-navbar">
                <Header />
            </nav>

            <Loading loading={loading}/>

            <div className="container-fluid header_bg_img_contact" style={{ marginTop: '-7rem' }}>
                <div className="container pb-5">
                    <div className="text-center" style={{ paddingTop: '12rem', paddingBottom: '4rem' }}>
                        <h1 className="fw-bold display-4 text-black">Contact</h1>
                        <div className="d-flex text-center align-items-center justify-content-center mt-3">
                            <h5 className="mx-2 fw-bold text-black">Home</h5>
                            <h5 className="mx-2 fw-bold text-black">Contact</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-danger p-4">

            </div>
            <div className="container mt-5 ">
                <div className="row align-items-center">
                    <div className="col-md-6 mb-lg-0 mb-4">
                        <h1 className="fw-bold">
                            Get in touch with Us today
                        </h1>
                        <h6 className="mt-1">
                            For any kind of query, contact us with the details below.
                        </h6>
                        <h6>
                            Roshan Lal 94174 71760
                        </h6>
                        <h6>
                            Ravi Kumar 94787 72062
                        </h6>
                        <h6>
                            Krishan Kumar 94645 29103
                        </h6>
                        <h6>
                            Landline +91 161 2605761 , 5054318
                        </h6>
                        <p className="mt-4 mb-4">
                            <a href="#" className="text-decoration-none text-secondary">
                                sharp_graphgears@yahoo.com , roshandiemaker@gmail.com
                            </a>
                        </p>
                        <p>
                            <a href="#" className="text-decoration-none text-secondary">
                                B- XXX , 3959 / 1 , St. No. 2 Hargobind Pura , Near Samrala Byepass , Ludhiana - 141010 , Punjab, India
                            </a>
                        </p>

                    </div>

                    <div className="col-md-6 mb-lg-0 mb-4">
                        <div className="contact_form p-5">
                            <h2>Massage with me</h2>
                            <div>
                                <div className="row mt-4 align-items-center">
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <input type="text" className="form-control bg-transparent" placeholder="Full Name" onChange={(e) => setFullname(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <input type="email" className="form-control bg-transparent " style={{ padding: 10 }} placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <textarea className="form-control bg-transparent" rows="6" placeholder="type your message..." onChange={(e) => setDescription(e.target.value)}></textarea>
                                </div>
                                <button type="submit" className="btn btn-danger rounded-0 mt-4 text-light px-4 py-2" onClick={handleMessage}>Send
                                    Massage</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <iframe className="w-100"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d-70.56068388481569!3d41.45496659976631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus"
                        height="500" style={{ border: 0, opacity: 0.7 }} allowfullscreen loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            

            <div className="container-fluid mt-5 p-5 bg-black">
                <div className="container">
                    
                    <div className="row mt-5 align-items-center text-light border-bottom pb-4 border-secondary contact_footer_content">
                        <div className="col-md-9">
                            <h1>Want to Know more about us?</h1>
                        </div>
                        <div className="col-md-3">

                        </div>
                    </div>
                    <div className="row mt-5 border-bottom pb-5 border-secondary">
                        <div className="col-md-4 mb-lg-0 mb-4">
                            <div>
                                <h4 className="text-light fw-bold mb-4">SHARP GRAPHS AND GEARS</h4>
                                <p className="mt-3 text-light">
                                    SHARP GRAPHS & GEARS is one of the established laser die maker founded in 1978 as a manual die maker and is located
                                    in Ludhiana City of Punjab. We are serving the industry since 1978 & became the
                                    solution provider to industry & also strategically growing with the emerging market in India.
                                    We thank to all our clients for doing business with us. As a quality minded entity & with primarily focused
                                    on quality
                                </p>
                                <p className="mt-4">
                                    <i className="ri-facebook-fill mx-2 rounded-circle p-2 text-light bg-danger"></i>
                                    <i className="ri-twitter-fill mx-2 border rounded-circle p-2 text-light"></i>
                                    <i className="ri-whatsapp-fill mx-2 border rounded-circle p-2 text-light"></i>
                                    <i className="ri-linkedin-box-fill mx-2 border rounded-circle p-2 text-light"></i>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-lg-0 mb-4">
                            <div className="text-light">
                                <h4 className="mb-4">Explore</h4>
                                <p>Home</p>
                                <p>About Us</p>
                                <p>Contact Us</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-lg-0 mb-4">
                            <div className="text-light">
                                <h4 className="mb-4">Get In touch</h4>
                                <p>
                                    B- XXX , 3959 / 1 , St. No. 2 Hargobind Pura , Near Samrala Byepass , Ludhiana - 141010 , Punjab , India
                                </p>
                                <a href="#" className="text-decoration-none text-light">sharp_graphgears@yahoo.com</a>

                                <p className="mt-2"><a href="#" className="text-decoration-none text-light">roshandiemaker@gmail.com</a></p>

                                <p>
                                    +91 0161 260 5761
                                </p>
                                <p>
                                    +91 0161 505 4318
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-5 text-light">
                        <div>
                            <p>
                                Copyright &copy;2023 Engview. All Rights Reserved.
                            </p>
                        </div>
                        <div>
                            <p>Privacy Policy &#x2022; Terms & Consition</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactUs    