import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assect/css/custom.css';
import logo from '../assect/images/logo.jpg';
import userphoto from './../assect/images/user_thumb.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import lib from "../../config/lib";

const Header = () => {
    const navigate = useNavigate();
    const [datadisplay, setDataDisplay] = useState([]);

    useEffect(() => {
        handleFethData();
    }, []);

    const handleFethData = async () => {
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('apikey', token);

            const response = await axios.post(lib.apiURL + '/' + lib.apis.header, formData);
            if (response.data.status === "success") {
                setDataDisplay(response.data.name);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }

    return (
        <div>
            <div id="container">
                <div className="row">
                    <div className="col">
                        <div id="header" className="blue_lin">
                            <div className="header_left">
                                <div className="logo">
                                    <img src={logo} width={140} height={61} alt="Ekra" />
                                </div>
                            </div>
                            <div className="mainicon">
                                <Link to="/dashboard" className="dashicon">Dashboard</Link>
                                <Link to="/support" className="supporticon">Support</Link>
                                <a className="supporticon" onClick={() => {
                                    localStorage.removeItem('token');
                                    localStorage.removeItem('role');
                                    navigate('/login');
                                }}>Logout</a>
                            </div>

                            <div class="header_right">
                                <div id="user_nav">
                                    <ul>
                                        <li class="user_thumb"><a href="#"><span class="icon"><img src={userphoto} width={30} height={30} alt="User" /></span></a></li>
                                        <li class="user_info"><span class="user_name">{datadisplay}</span><span>User</span></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;