import React, { useEffect } from "react";
import Header from "./common/header";
import TopMenu from "./common/topmenu";
import DashMain from "./bodyfiles/selected";
import Graph from "./bodyfiles/graph";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';

const Dashboard = () => {
    return (
        <div id="theme-default" className="full_block">
            <Header />
            <TopMenu />
            <div id="content">
                <div className="fullbody">
                    <DashMain />
                    <Graph />
                    {/* <Table /> */}
                </div>
            </div>
        </div>
    )
}
export default Dashboard;