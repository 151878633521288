import React from "react";
import '../assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
// main bootstrap5 
import "bootstrap/dist/css/bootstrap.min.css";
import '../assets/css/bootstrap.min.css';
import '../assets/css/bootstrap.min.css.map';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
import Loading from "../common/Loading";


function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('email', email);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.forgetpassword, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Rest Password Link Sent to Email!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (

        <div className="container">
            <Loading loading={loading} />
            <div className="row mx-auto bg-white w-75 align-items-center rounded-4 shadow-lg login_container">

                <div className="col-sm-6 ps-0 mb-lg-0 mb-4">
                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="../assets/images/SportsCo.png" className="d-block w-100 loginslider1" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="../assets/images/SportsCo.png" className="d-block w-100 loginslider2" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-sm-6 mb-lg-0 mb-4">
                    <div className="mx-3 p-2">
                        <h3 className="login-text">Forgot Password!</h3>
                        <p>Reset password </p>
                        <form>
                            <div className="mb-3">
                                <label for="exampleInputEmail1" className="form-label mb-1">Email-id</label>
                                <input type="email" className="form-control p-2" id="exampleInputEmail1" placeholder="Enter your email" name="email" onChange={(e) => setEmail(e.target.value)} required />
                            </div>

                            <input type="submit" className="btn btn-danger w-100 mb-2" value="Reset Password" name="login" onClick={handleLogin} />
                            <div className="d-flex mb-3 align-items-center">
                                <div style={{ flex: '1', height: '1px', backgroundColor: '#000' }}></div>
                                <div className="mx-4">
                                    OR
                                </div>
                                <div style={{ flex: '1', height: '1px', backgroundColor: '#000' }}></div>
                            </div>
                            <div className="text-center">
                                <Link to="/login" className="text-decoration-none"> Already Forgot?
                                    <span className="text-danger ms-2">
                                        Login
                                    </span>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>

    )
}
export default ForgotPassword;
