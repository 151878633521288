import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
import Loading from "../common/Loading";

const ChangePassword = () => {
    const [newPass, setNewPass] = useState();
    const [confirmPass, setConfirmPass] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);

    const handleChangePassword = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        const token = localStorage.getItem('token');
        formData.append('np', newPass);
        formData.append('cp', confirmPass);
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.changepassword, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Password Changed Successfully', 'success');
                setLoading(false);
            }
            else {
                setMessage(response.data.msg);
                Swal.fire({
                    title: 'opps......',
                    text: message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <div id="content">
                <div class="fullbody">
                    <Loading loading={loading} />
                    <div class="grid_container">
                        <div class="grid_12 full_block">
                            <div class="widget_content">
                                <form method="post" class="form_container">
                                    <ul>
                                        <li class="form_grid_4">
                                            <div>
                                                <label class="field_title">New Password</label>
                                                <div class="form_input ">
                                                    <input name="np" type="password" onChange={(e) => { setNewPass(e.target.value) }} required className="p-2 form-control" />
                                                </div>
                                            </div>
                                        </li>
                                        <li class="form_grid_4">
                                            <div>
                                                <label class="field_title">Conform Password</label>
                                                <div class="form_input">
                                                    <input name="cp" type="password" onChange={(e) => { setConfirmPass(e.target.value) }} required className="p-2  form-control" />
                                                </div>
                                            </div>
                                        </li>
                                        <li class="form_grid_6 sbbt">
                                            <div>
                                                <div class="form_input">
                                                    <input type="submit" class="btn_small btn_blue" name="cpbt" value="Change Password" onClick={handleChangePassword} />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="clear"></span>
            </div>
        </div>
    )
}
export default ChangePassword;