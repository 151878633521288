import React, { useState, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';

import Header from "./common/header";
import TopMenu from "./common/topmenu";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
import Form from 'react-bootstrap/Form';
import { Rule } from '@mui/icons-material';
import Loading from '../common/Loading';

const Keyline = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [rulechange, setRulechange] = useState('N/A');
    const [sameasbefore, setSameasbefore] = useState('N/A');
    const [quality, setquality] = useState('N/A');
    const [type, setType] = useState('N/A');
    const [orderid, setOrderid] = useState('N/A');
    const [cuttingselect, setCuttingselect] = useState('N/A');
    const [crusingselect, setCrusingselect] = useState('N/A');
    const [oldflint, setOldFlint] = useState('N/A');
    const [description, setDescription] = useState('N/A');

    const [jobname, setJobname] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
    };

    const handleCustomeOrder = async (event) => {
        event.preventDefault();
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('rulechange', rulechange);
        formData.append('samediebefor', sameasbefore);
        formData.append('orderid', orderid);
        formData.append('description', description);
        formData.append('quality', quality);
        formData.append('type', type);
        formData.append('crusingselect', crusingselect);
        formData.append('cuttingselect', cuttingselect);
        formData.append('olddieflnit', oldflint);
        formData.append('addfile', selectedFile);
        formData.append('jobname', jobname);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.rulechange, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Order Created!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    function rulechangedetails(e) {
        setRulechange(e.target.value);
        var rulechangedetails = document.getElementById("rulechangedetails");
        var rulechange = document.getElementById("rulechange");
        var repeatkeyline = document.getElementById("repeatkeyline");

        if (rulechangedetails.value === "Rule Change") {
            rulechange.style.display = "block";
            repeatkeyline.style.display = "none";
        }
        else {
            if (rulechangedetails.value === "Repeat") {
                rulechange.style.display = "none";
                repeatkeyline.style.display = "block";
            }
            else {
                rulechange.style.display = "none";
                repeatkeyline.style.display = "none";
            }
        }
    }

    function olddie(e) {
        setSameasbefore(e.target.value);
        var olddies = document.getElementById("olddies");
        var typecutting = document.getElementById("typecutting");
        var orderid = document.getElementById("orderid");
        var chekbox = document.getElementById("chekbox");

        console.log(olddies.value)

        if (olddies.value === "Yes") {
            typecutting.style.display = "none";
            orderid.style.display = "block";
            chekbox.style.display = "none";
        }
        else {
            if (olddies.value === "No") {
                typecutting.style.display = "block";
                orderid.style.display = "none";
                chekbox.style.display = "block";
            }
        }

    }

    function handleOnchange(e) {
        setSameasbefore(e.target.value);
        var diedetails = document.getElementById('diedetails');
        var repeatdie = document.getElementById('repeatdie');
        var orderinput = document.getElementById('orderinput');
        var olddieflint = document.getElementById('olddieflint');
        var quality = document.getElementById('quality');

        if (diedetails.value === "Yes") {
            repeatdie.style.display = 'none';
            orderinput.style.display = 'block';
            olddieflint.style.display = 'none';
            quality.style.display = 'none';
        }
        else {
            if (diedetails.value === "No") {
                repeatdie.style.display = 'block';
                orderinput.style.display = 'none';
                olddieflint.style.display = 'block';
                quality.style.display = 'block';
            }
            else {
                repeatdie.style.display = 'none';
                orderinput.style.display = 'none';
                olddieflint.style.display = 'none';
                quality.style.display = 'none';
            }
        }
    }

    return (
        <div id="theme-default" className="full_block">
            <Header />
            <TopMenu />
            <div id="content">
                <Loading loading={loading} />
                <div className="row ">
                    <div className="col-md-12 d-flex justify-content-center">
                        <h3>Rule Change</h3>
                    </div>
                    <div className="col-md-12 "><hr /></div>

                </div>

                <div className="fullbody" style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                    <div class="grid_container">
                        <div class="row justify-content-center">
                            <div className='col-md-6'>
                                <form id="form-file-upload" className="beautiful-form">
                                    <div className="upload-container">
                                        <label id="label-file-upload" htmlFor="input-file-upload" className="upload-label">
                                            <div className="drag-drop-box">
                                                <p>
                                                    {selectedFile
                                                        ? `File Uploaded: ${selectedFile.name}`
                                                        : 'Drag and drop your file here or'}
                                                </p>
                                            </div>
                                        </label>
                                        <input type="file" id="input-file-upload" multiple={false} onChange={handleFileChange} className="file-input" />
                                        <div className='d-flex mt-3'><label>Rule Change</label></div>
                                        <Form.Select aria-label="Default select example" id="rulechangedetails" style={{ marginTop: '10px' }} onChange={rulechangedetails}>
                                            <option disabled selected>Select Rule Change</option>
                                            <option value="Rule Change">Rule Change
                                            </option>
                                            <option value="Repeat">Repeat</option>
                                            <option value="Repair">Repair</option>
                                        </Form.Select>
                                        <div className='col-md-12 mt-2'>
                                            <div className='d-flex'><label>Job name</label></div>
                                            <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter job name" onChange={(e) => setJobname(e.target.value)} />
                                        </div>
                                        <div id='rulechange' style={{ display: 'none' }}>
                                            <Fragment>
                                                <div className='d-flex mt-3'><label>Same as old</label></div>
                                                <Form.Select aria-label="Default select example" id="olddies" onChange={olddie} style={{ marginTop: '10px' }} >
                                                    <option disabled selected>Select same as old</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </Form.Select>

                                                <div style={{ display: 'none' }} id="rulequality">
                                                    <div className='d-flex mt-3'><label>Rule Quality</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setquality(e.target.value)} >
                                                        <option disabled selected>Select Rule Quality</option>
                                                        <option value="semi">Semi
                                                        </option>
                                                        <option value="premium">Premium</option>
                                                        <option value="advance">Advance</option>
                                                    </Form.Select>
                                                </div>

                                                <div style={{ display: 'none' }} id="typecutting">
                                                    <div className='d-flex mt-3'><label>Select Type</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setType(e.target.value)} >
                                                        <option disabled selected>Select Type</option>
                                                        <option value="cutting">cutting
                                                        </option>
                                                        <option value="crusing">crusing</option>
                                                    </Form.Select>
                                                </div>

                                                <div class="input-group" style={{ display: 'none' }} id="orderid" >
                                                    <div className='d-flex '><label>Order/Job</label></div>
                                                    <input type="text" placeholder="Enter order id / job number" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setOrderid(e.target.value)} />
                                                </div>

                                                <div className='row mt-3' id="chekbox" style={{ display: 'none' }}>
                                                    <div className='col-md-2'>
                                                        <div><label className='mb-4 ms-0'>All Cutting</label></div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios1" value="Yes" onChange={(e) => setCuttingselect(e.target.value)} />
                                                            <label class="form-check-label" for="exampleRadios1">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios2" value="No" onChange={(e) => setCuttingselect(e.target.value)} />
                                                            <label class="form-check-label" for="exampleRadios2">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div><label className='mb-4 ms-0'>All Crusing
                                                        </label></div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="Yes" onChange={(e) => setCrusingselect(e.target.value)} />
                                                            <label class="form-check-label" for="exampleRadios3">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="No" onChange={(e) => setCrusingselect(e.target.value)} />
                                                            <label class="form-check-label" for="exampleRadios4">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Fragment>
                                        </div>

                                        <div id='repeatkeyline' style={{ display: 'none' }}>
                                            <Fragment>
                                                <div className='d-flex mt-3'><label>Same as old</label></div>
                                                <Form.Select aria-label="Default select example" id="diedetails" style={{ marginTop: '10px' }} onChange={handleOnchange} >
                                                    <option disabled selected>Select same as old</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </Form.Select>

                                                <div className='row mt-3' id="repeatdie" style={{ display: 'none' }}>
                                                    <div className='col-md-2'>
                                                        <div><label className='mb-4 ms-0'>All Cutting</label></div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios1" value="Yes" onChange={(e) => setCuttingselect(e.target.value)} />
                                                            <label class="form-check-label" for="exampleRadios1">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios2" value="No" onChange={(e) => setCuttingselect(e.target.value)} />
                                                            <label class="form-check-label" for="exampleRadios2">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div><label className='mb-4 ms-0'>All Crusing
                                                        </label></div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="Yes" onChange={(e) => setCrusingselect(e.target.value)} />
                                                            <label class="form-check-label" for="exampleRadios3">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="No" onChange={(e) => setCrusingselect(e.target.value)} />
                                                            <label class="form-check-label" for="exampleRadios4">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="input-group" style={{ display: 'none' }} id="orderinput" >
                                                    <div className='d-flex '><label>Order/Job</label></div>
                                                    <input type="text" placeholder="Enter order id / job number" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setOrderid(e.target.value)} />
                                                </div>

                                                <div style={{ display: 'none' }} id="olddieflint" >
                                                    <div className='d-flex '><label>Old Die Flint</label></div>
                                                    <input type="text" placeholder="old die flnit" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setOldFlint(e.target.value)} />
                                                </div>

                                                <div style={{ display: 'none' }} id="quality" >
                                                    <div className='d-flex '><label>Quality</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setquality(e.target.value)} >
                                                        <option disabled selected>Select quality</option>
                                                        <option value="semmi">semmi </option>
                                                        <option value="premium">premium</option>
                                                        <option value="as per old">as per old</option>
                                                    </Form.Select>
                                                </div>

                                            </Fragment>
                                        </div>

                                        <div className='d-flex mt-3'><label>Description</label></div>
                                        <textarea id="message-box" placeholder="Description" onChange={(e) => setDescription(e.target.value)}></textarea>

                                        <input type="submit" value="Submit Key Line" className="upload-button" onClick={handleCustomeOrder} />

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Keyline;