'useclient'
import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import lib from '../config/lib';
import { FaRegEdit } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import Modal from 'react-bootstrap/Modal';
import Description from '../DescriptionModal';
import Loading from '../common/Loading';
import DeleteJob from '../DeleteButton';

function RuleChange() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        sethandleOrderidsend(id);
    }

    const [shows, setShows] = useState(false);

    const [jobnumberdescription, setJobnumberdescription] = useState('');

    const handleCloses = () => setShows(false);
    const handleShows = (id) => {
        setShows(true);
        setJobnumberdescription(id);
    }

    useEffect(() => {
        handleSample();
    }, []);

    const handleSample = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.rulechangeorder, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleSearch = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'rulechange');
        formData.append('search', search);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.orderserch, formData);
            setData(response.data);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleFilter = async (e) => {
        var filter = e.target.value;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', filter);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.rulechangeorder, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    // Modal 

    const [rulechange, setrulechange] = useState('');
    const [quality, setquality] = useState('');
    const [type, settype] = useState('');
    const [crusingselect, setcrusingselect] = useState('');
    const [cuttingselect, setcuttingselect] = useState('');
    const [samediebefore, setsamediebefore] = useState('');
    const [jobno, setjobno] = useState('');
    const [olddieflnit, setolddieflint] = useState('');
    const [description, setdescription] = useState('');
    const [sid, setsid] = useState('');
    const [date, setdate] = useState('');
    const [status, setstatus] = useState('');
    const [productimg, setproductimg] = useState('');

    const [designerimg, setDesignerImg] = useState('');

    const [handleOrderidsend, sethandleOrderidsend] = useState('');

    useEffect(() => {
        handleKeylineFeth();
    }, []);

    const handleKeylineFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.rulechangedisplay, formData);
            if (response.data.status === "success") {
                setrulechange(response.data.rulechange);
                setquality(response.data.quality);
                settype(response.data.type);
                setcrusingselect(response.data.crusingselect);
                setcuttingselect(response.data.cuttingselect);
                setsamediebefore(response.data.samediebefore);
                setjobno(response.data.jobno);
                setolddieflint(response.data.olddieflnit);
                setdescription(response.data.description);
                setsid(response.data.sid);
                setdate(response.data.date);
                setstatus(response.data.tracker);
                setproductimg(response.data.productimg);
                handleDesignerimg();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleDesignerimg = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        // formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.designerimg, formData);
            if (response.data.status == "success") {
                setDesignerImg(response.data.designerimg);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="row align-items-center">
                <Loading loading={loading} />
                <div className="col-lg-3 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setSearch(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div className="col-lg-2 ms-lg-auto">
                    <div className="me-2">
                        <Form.Select aria-label="Default select example" onChange={handleFilter}>
                            <option disabled>Select filter</option>
                            <option value="All">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Complete</option>
                        </Form.Select>
                    </div>
                </div>
            </div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Job Number</th>
                        <th>Job Name</th>
                        <th>Rulechange</th>
                        <th>Quality</th>
                        <th>Type</th>
                        <th>Crusing Select</th>
                        <th>Cutting Select</th>
                        <th>Same Die Before</th>
                        <th>Olddie Flnit</th>
                        <th>Description</th>
                        <th>Product Img</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData && currentPageData.map((item) => (
                        <tr>
                            <td>{item.orderid}</td>
                            <td>{item.jobname}</td>
                            <td>{item.rulechange}</td>
                            <td>{item.quality}</td>
                            <td>{item.type}</td>
                            <td>{item.crusingselect}</td>
                            <td>{item.cuttingselect}</td>
                            <td>{item.samediebefore}</td>
                            <td>{item.olddieflnit}</td>
                            <td>{item.description}</td>
                            <td><a href={lib.imgurl + '/' + item.productimg} target="_blank" >View Image</a></td>
                            <td>{item.date}</td>
                            <td>{item.status}</td>
                            <td className='d-flex'>
                                <LuEye onClick={() => handleShow(item.orderid)} size={20} />
                                {/* <FaRegEdit onClick={() => handleShows(item.orderid)} size={20} className='ms-3' /> */}
                                <span className="ms-2"><DeleteJob handleJobnumber={item.orderid} handleJobfrom={"rulechange"}/></span>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Rule Change</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>

                        <div className='col-md-12 d-flex justify-content-center mb-3' >
                            <span className=' invoce-tittle'>View Job Card</span>
                        </div>
                        {/* <div className='col-md-4'>
                            <span style={{ fontSize: 15 }}>Customer Image</span><span className='ms-2'><a href={lib.imgUrl + '/' + productimg} target="_blank" >View Image</a></span>
                        </div>

                        <div className='col-md-4'>
                            <span style={{ fontSize: 15 }}>Designer Image</span><span className='ms-2'><a href={lib.imgUrl + '/' + designerimg} target="_blank" >View Image</a></span>
                        </div> */}

                        <div className='col-md-6 mt-2'>
                            <label>Job no</label>
                            <input className='form-control' placeholder='Job no' value={jobno} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Customer code</label>
                            <input className='form-control' placeholder='customer code' value={sid} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Rulechange</label>
                            <input className='form-control' placeholder='Category' value={rulechange} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Quality</label>
                            <input className='form-control' placeholder='Box style' value={quality} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Type</label>
                            <input className='form-control' placeholder='Type' value={type} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Crusign Select</label>
                            <input className='form-control' placeholder='Length' value={crusingselect} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Cutting Select</label>
                            <input className='form-control' placeholder='Width' value={cuttingselect} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Same Die Before</label>
                            <input className='form-control' placeholder='Height' value={samediebefore} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>OldDieFlint</label>
                            <input className='form-control' placeholder='OldDieFlint' value={olddieflnit} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Description</label>
                            <input className='form-control' placeholder='Height' value={description} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Date</label>
                            <input className='form-control' placeholder='Date' value={date} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Status</label>
                            <input className='form-control' placeholder='Status' value={status} />
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label>Producting</label>
                            <input className='form-control' placeholder='Status' value='Re emr' />
                        </div>

                        {/* <div className='col-md-6 mt-2 d-flex align-items-center'>
                            <span style={{ fontSize: 15 }}>Priting Machine Data</span><span className='ms-2'><DuplexSelected handleSendsid={handleSendsid} /></span>
                        </div>
                        <div className='col-md-6 mt-2 d-flex align-items-center'>
                            <span style={{ fontSize: 15 }}>Die Machine Data</span><span className='ms-2'><DuplexSelect handleSendsid={handleSendsid} /></span>
                        </div> */}
                    </div>
                </Modal.Body>
                    <div className='row'>
                        <div className='col-lg-2'>
                            <Button className='btn-primary' onClick={handleKeylineFeth}>Feth Data</Button>
                        </div>
                    </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Description */}

            <Modal show={shows} onHide={handleCloses} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <Description jobnumberdescription={jobnumberdescription} handleJobfrom={'rulechange'} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloses}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default RuleChange;    