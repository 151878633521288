import React, { useEffect } from "react";
import '../assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
// main bootstrap5 
import "bootstrap/dist/css/bootstrap.min.css";
import '../assets/css/bootstrap.min.css';
import '../assets/css/bootstrap.min.css.map';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
import LOGO from '../assets/images/LOGO.png'
import Form from 'react-bootstrap/Form';
import Loading from "../common/Loading";

function Register() {
    const [name, setName] = useState('');
    const [companyname, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');
    const [loading, setLoading] = useState(false);

    const handleCheckUser = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('email', email);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.signfeth, formData);
            if (response.data.status === "exist") {
                Swal.fire({
                    title: 'Failed',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
            else {
                handleRegister();
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleRegister = async () => {

        const formData = new FormData();
        formData.append('name', name);
        formData.append('cname', companyname);
        formData.append('email', email);
        formData.append('mobile', number);
        formData.append('pass', password);
        formData.append('role', role);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.signup, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Registered successfully successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'User id or password not mached or not approved by admin',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }
    return (

        <div className="login-bg">
            <div className="container login-height">
                <Loading loading={loading} />

                <div className="row justify-content-center ">
                    <div className="row justify-content-center mb-3 mt-3">
                        <div className="col-sm-2">
                            <img src={LOGO} alt="loginImage" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 shadow-sm p-5 pt-4 rounded-3 bg-white">
                        <h1 className="mb-4 text-center login-text">
                            Create account
                        </h1>
                        <label for="name" className="form-label m-0">Your name</label>
                        <input type="text" className="form-control" placeholder="Full name" name="name" onChange={(e) => setName(e.target.value)} required />

                        <label for="Number" className="form-label m-0 mt-3">Mobile Number</label>
                        <input type="text" className="form-control" placeholder="Enter your Number" onChange={(e) => setNumber(e.target.value)} name="mobile" required />
                        <label for="email" className="form-label m-0 mt-3">Email</label>
                        <input type="email" className="form-control p-2" placeholder="Enter your E-mail" onChange={(e) => setEmail(e.target.value)} name="email" required />
                        <label for="comapny" className="form-label m-0 mt-3">Company name</label>
                        <input type="text" className="form-control" placeholder="Enter Company Name" onChange={(e) => setCompanyName(e.target.value)} name="cname" required />

                        <label for="password" className="form-label m-0 mt-3">Password</label>
                        <input type="password" className="form-control p-2" placeholder="Enter your password" name="pass" onChange={(e) => setPassword(e.target.value)} required />

                        <h5>
                            <Form.Check aria-label="option 1" label={'by signingup I agree to the'} className="mb-3 pt-0" />
                            <p> <span className="text-danger">terms & conditions</span> and <span className="text-danger">privacy policy</span></p>
                        </h5>

                        <input type="submit" className="btn custom-color w-100 mb-2 mt-3" value="Create An Account" name="signup" onClick={handleCheckUser} />

                        <h6 className="text-center">
                            <Link to="/login" className="text-decoration-none"> New to G&G Laser?
                                <span className="second-color ms-2">
                                    Sign In.
                                </span>
                            </Link>
                        </h6>
                    </div>
                </div>

            </div>
        </div>

    )
}
export default Register;
