import React, { useState, useEffect } from 'react';
import './css/Invoice.css';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';


const InvoicePayment = ({ jobno, jobform }) => {
    const [data, setData] = useState([]);
    const [extraamount, setExtraamount] = useState('0');
    const [description, setDescription] = useState('');

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.stockdisplayemploy, formData);
            setData(response.data);
            if (Array.isArray(response.data) && response.data.length > 0) {
                // Accessing the first item in the array (you might need to adjust based on your API response structure)
                const firstItem = response.data[0];
    
                // Accessing and setting the values
                setExtraamount(firstItem.extraamount);
                setDescription(firstItem.description);
            } else {
                console.log("Invalid or empty response data");
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }



    // const calculateTotal = () => {
    //   if (!data) {
    //     return 0;
    //   }

    //   return data.reduce((total, item) => total + item.quantity * item.rate, 0);
    // }
    const GrandTotal = () => {
        if (!data) {
            return 0;
        }

        // return data.reduce((total, item) => total + item.quantity * item.rate, 0);
        const subtotal = data.reduce((total, item) => total + item.quantity * item.rate, 0);
        const gst = subtotal * 18 / 100;
        const grandTotal = subtotal + (extraamount ? parseFloat(extraamount) : 0);

        return grandTotal;
    }

    return (
        <div className="invoice-container">

            <div className="content">
                <table>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.item}</td>
                                <td>{item.quantity}</td>
                                <td>₹{item.rate}</td>
                                <td>₹{item.quantity * item.rate}</td>
                            </tr>
                        ))}
                        <tr>
                            <td><label>Extra Amount Description</label><input type='text' value={description} disabled/></td>
                            <td></td>
                            <td></td>
                            <td><p className="total" style={{ fontSiz: '20px' }}>Extra Amount ₹{extraamount}</p></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="footer">
                <p className="total">Total: ₹{GrandTotal()}</p>
                <p className="total">GST: ₹{GrandTotal()*18/100}</p>
                <p className="total">Grand Total: ₹{GrandTotal()*18/100+GrandTotal()}</p>
            </div>
            <div className='p-2'>
                {/* <Button variant='success' onClick={handleOrder}>Submit Data</Button> */}
            </div>
        </div>
    );
};

export default InvoicePayment;

