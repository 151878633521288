import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axios from 'axios';
import lib from "./config/lib";
import Swal from "sweetalert2";

const Description = ({ jobnumberdescription, handleJobfrom }) => {
    const [eiditdescription, setEiditdescription] = useState('');

    const handleDescription = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('description', eiditdescription);
        formData.append('jobfrom', handleJobfrom);
        formData.append('jobno', jobnumberdescription)

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.customerdescription, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Details Updated Successfully!', 'success');
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Job is in process you an not edit please contact support',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
        }
    }

    return (
        <div>
            <div className='row p-2'>
                <div className='col-lg-12'>
                    <label>Write The Things To Be Eidited</label>
                    <textarea rows={10} placeholder='Enter message...' onChange={(e) => setEiditdescription(e.target.value)}></textarea>
                </div>
                <div className='col-lg-12'>
                    <Button onClick={handleDescription} >Send</Button>
                </div>
            </div>
        </div>
    )
}

export default Description