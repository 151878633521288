import 'bootstrap/dist/css/bootstrap.css';
// main bootstrap5 
import "bootstrap/dist/css/bootstrap.min.css";
import '../assets/css/bootstrap.min.css';
import '../assets/css/bootstrap.min.css.map';
import './styles.css'

// import retangler from '../assets/images/Rectangle 5.png';
// import group11 from '../assets/images/Group 11.png';
// import retinglar17 from '../assets/images/Rectangle 17.png';
// import blog1 from '../assets/images/blog_1_1.jpg.png';
// import blog2 from '../assets/images/blog_1_2.jpg.png';
// import blog3 from '../assets/images/blog_1_3.jpg.png';
// import thumb1 from '../assets/images/wcu-thumb1_1.png.png';
// import test1 from '../assets/images/testi_1_2.jpg.png';
// import test2 from '../assets/images/testi_1_2.jpg.png';
// import test3 from '../assets/images/testi_1_2.jpg.png';
import test4 from '../assets/images/testi_1-1.png.png';
import team1 from '../assets/images/div.team-img.png';
import team2 from '../assets/images/div.team-img_2.png';
import team3 from '../assets/images/team_1_3.jpg.png';
import team4 from '../assets/images/div.team-img_3.png';
import mainlogo from './imgs/main-logo.png'
import tstimg from './imgs/tst-img.jpg'
import fb from './imgs/fb-logo.png'
import whatsapp from './imgs/whatsapp.png'
import linkedin from './imgs/linkedin.png'
import twitter from './imgs/twitter.png'
import androidApp from './imgs/app.png'
import aboutimg from './imgs/about-img.png'
import googleplay from './imgs/googleplay.png'
import infrastructure from './imgs/infrastructure.png'
import Smallflap from '../assets/images/Smallflap.jpg'
import TRAY from '../assets/images/TRAY.jpg'
import Bigflap from '../assets/images/Bigflap.jpg'
import Cakebox from '../assets/images/Cakebox.jpg'
import CRASH_LOCK_CORRUGATED_Coro from '../assets/images/CRASH_LOCK_CORRUGATED_Coro.png'
import FULL_CARTONDOUBLERRULE_Coro from '../assets/images/FULL_CARTONDOUBLERRULE_Coro.jpg'
import FULL_CARTON_SINGLE_RULE_Coro from '../assets/images/FULL_CARTON_SINGLE_RULE_Coro.jpg'
import REVERSETUCKCORRUGATEDCoro from '../assets/images/REVERSETUCKCORRUGATEDCoro.jpg'
import ECONOMICAL_FOLDING_CORRUGATION_Coro from '../assets/images/ECONOMICAL_FOLDING_CORRUGATION_Coro.jpg'
import HANGINGBOXCoro from '../assets/images/HANGINGBOXCoro.jpg'
import TruntypeCoro from '../assets/images/TruntypeCoro.jpg'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import lib from '../config/lib';
import Swal from "sweetalert2";




const Landing = () => {

    const [address, setAddress] = useState('');
    const [mobile, setMobile] = useState('');
    const [emaicompany, setEmaicompany] = useState('');

    useEffect(() => {
        handleSupportNumber();

    }, []);

    const handleSupportNumber = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.supportdetails);
            setEmaicompany(response.data.email);
            setAddress(response.data.address);
            setMobile(response.data.mobile);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    };


    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');

    const handleMessage = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('fullname', fullname);
        formData.append('email', email);
        formData.append('description', description);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.messageindex, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Registered successfully successfully!', 'success');
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }


    return (

        < div >

            <nav className="navbar bg-color navbar-expand-lg fixed-top">
                <div className="container">
                    <a className="navbar-brand" href="#"><img src={mainlogo} alt='...' /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link active-custom active" aria-current="page" href="#home" style={{ color: '#fff' }}>Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active-custom" href="#about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active-custom" href="#product">Our Product</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active-custom" href="#team">Our Team</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active-custom" href="#contact">Contact Us</a>
                            </li>
                        </ul>
                        <div className="nav-btns">
                            <Link to="/register" >Sign Up</Link>
                            <Link to="/login" className="login-btn">LOGIN</Link>
                        </div>
                    </div>
                </div>
            </nav>
            <main>
                <div className="home-page">
                    {/* <!----------------Banner---------------------------------> */}
                    <section id='home'>
                        <div className="main-banner">
                            <div className="banner-info">
                                <h1>AUTOMATIC<br />DIE MAKING <br />SYSTEM</h1>
                                <Link to="/register" className="banner-btn" >Sign Up</Link>
                            </div>
                        </div>
                        <div className="our-clients">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4 col-6">
                                        <div className="inner-clnt-part">
                                            <h2>100+</h2>
                                            <p>Project done</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="inner-clnt-part">
                                            <h2>225K</h2>
                                            <p>Our Clients</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="inner-clnt-part">
                                            <h2>98%</h2>
                                            <p>Satisfied Customer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!---=====================---Our Products-===========================--> */}
                    <section id='product'>
                        <div className="our-products">
                            <div className="container">
                                <h2 className="main-head">Our Products</h2>
                                {/* <!---------Row-----------> */}
                                <div className="row">
                                    <div className="col-md-3 col-6">
                                        <img src={TruntypeCoro} alt='....' className='ourproductborder' />
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={HANGINGBOXCoro} alt='....' className='ourproductborder' />
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={TRAY} alt='....' className='ourproductborder' />
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={ECONOMICAL_FOLDING_CORRUGATION_Coro} alt='....' className='ourproductborder' />
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={FULL_CARTON_SINGLE_RULE_Coro} alt='....' className='ourproductborder' />
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={FULL_CARTONDOUBLERRULE_Coro} alt='....' className='ourproductborder' />
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={FULL_CARTONDOUBLERRULE_Coro} alt='....' className='ourproductborder' />
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={FULL_CARTON_SINGLE_RULE_Coro} alt='....' className='ourproductborder' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!---=====================---Infrastructure-===========================--> */}
                    <div className="infrastructre">
                        <div className="container">
                            <div className="text-center">
                                <h2 className="main-head">Infrastructure</h2>
                            </div>
                            {/* <!---------Row-----------> */}
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="infrastructre-info">
                                        <h3>infrastructure</h3>
                                        <p>We have developed an ultra-modern infrastructure at Ludhiana, Punjab, India. For the ease of
                                            management, we have segregated the infrastructure into various departments. These departments are
                                            well-equipped with all the modern machinery, tools and equipment that are frequently lubricated &
                                            upgraded to keep abreast of the technological advancements. Further, the entire machinery is handled by
                                            expert professionals to assure a safe production period.</p>
                                        <h5>Our infrastructure is segregated into the following departments:</h5>
                                        <ul>
                                            <li>Quality control</li>
                                            <li>Production unit</li>
                                            <li>Research & development unit</li>
                                            <li>Logistics department</li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-md-5">
                                    <img src={infrastructure} className="img-fluid" alt='....' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!---=====================---Infrastructure-===========================--> */}
                    <div className="testimonials">
                        <div className="container">
                            <div className="text-center">
                                <h2 className="main-head">Testimonials</h2>
                            </div>
                            <div className="textimonial-slider">


                                {/* <!---&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&--> */}
                                <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="card-wrapper container-sm d-flex  justify-content-around">
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt='....' />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt='....' />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt="..." />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="card-wrapper container-sm d-flex   justify-content-around">
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt="..." />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt="..." />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt="..." />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="card-wrapper container-sm d-flex  justify-content-around">
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt="..." />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt="..." />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cards  " style={{ width: '30%' }}>
                                                    {/* <img src="imgs/stars.png" className="card-img-top" alt="..." /> */}
                                                    <div className="card-bodys">
                                                        <h5 className="card-titles">Greate services</h5>
                                                        <p>Proin convallis consectetur feugiat. Mauris consectetur rhoncus nunc non
                                                            condimentum. Fusce consequat vehicula orci quis laoreet. Aenean eu nibh
                                                            tempus, ornare sapien ut, auctor justo. Sed sagittis ligula vel neque.</p>
                                                        <div className="d-flex">
                                                            <img src={tstimg} alt="..." />
                                                            <div>
                                                                <h6>Ram Singh</h6>
                                                                <p>Founder and Ceo</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                                            data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                                            data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-----=====================--ABout Us-------=====================================--> */}
                    <section id='about'>
                        <div className="about-us" >
                            <div className="container">
                                <div className="text-center">
                                    <h2 className="main-head">About Us</h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <img src={aboutimg} alt="..." />
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-info">
                                            <h3>About Our Company</h3>
                                            <p>SHARP GRAPHS & GEARS is one of the established laser die maker founded in 1978 as a manual die maker
                                                and is located in Ludhiana City of Punjab. We are serving the industry since 1978 & became the solution
                                                provider to industry & also strategically growing with the emerging market in India. We thank to all our
                                                clients for doing business with us. As a quality minded entity & with primarily focused on quality we
                                                are the first in Ludhiana City of Punjab to introduce laser dies by installing the latest machinery in
                                                Laser Cutting & Auto Rule Processor. We are the first to equip the world className State Of The Art,
                                                Technical Knowhow, Large Experience which enables us to provide any kind of solution to the printing &
                                                packaging industry.
                                            </p>
                                            <h5>About Our Company</h5>
                                            <p>We Manufacturers of all kinds of Manual & Laser Dies For Card Board Boxes, Corrugated Carton, Label,
                                                Cuff, Collar & Ladies Suit Gala Cutting, Wedding Cards & Boxes, Gaskets, Blister Cutting, Tags Dies
                                                Etc.. We manufacture these products incorporating genuine grade of imported materials, which is
                                                specialized for die making. Prior to using them, a separate team of quality analysts stringently test
                                                them. This enables us to ensure adherence to international Quality Standards. In addition, we also
                                                provide services of Laser cutting, catering to several industries of Architect industry, Automobile
                                                Industry, computer Industry and Cosmetic Industry & all sorts of wood works. With real-time experience
                                                and full grasp of the market functionality, we have been able to carry our business in a prolific way.
                                                We work with full transparency, and serve clients in a cost-effective way. We have been able to meet
                                                customer’s varied needs by providing products that are widely appreciated for their varied associated
                                                attributes. Under the Strict Direction of “Mr. Roshan Lal, Mr.Ajay Kumar, Mr. Ravi Kumar & Mr. Krishan
                                                Kumar”, we have achieved an alleged name in the Die Making Industry.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!-----=====================--Our team-------=====================================--> */}
                    <section id='team'>
                        <div className="our-team">
                            <div className="container">
                                <div className="text-center">
                                    <h2 className="main-head">Our Experience Team</h2>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-6">
                                        <img src={team1} alt="..." className='team1border' />
                                        <div className="team-info">
                                            <h4>Mr. ROSHAN LAL</h4>
                                            <h5> Started business in 1978
                                                Experience of 41 Years</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={team2} alt="..." className='team2border' />
                                        <div className="team-info">
                                            <h4>Mr.AJAY KUMAR</h4>
                                            <h5> Joined business in 1993
                                                Experience of 26 Years</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={team3} alt="..." className='team3border' />
                                        <div className="team-info">
                                            <h4>Mr. RAVI KUMAR</h4>
                                            <h5> Joined business in 2001
                                                Experience of 18 Years</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <img src={team4} alt="..." className='team4border' />
                                        <div className="team-info">
                                            <h4>Mr. KRISHAN KUMAR</h4>
                                            <h5>  Joined business in 2004
                                                Experience of 15 Years</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-----=====================--Contact Us-------=====================================--> */}
                    <section id='contact'>
                        <div className="contact-us">
                            <div className="container">
                                <div className="text-center">
                                    <h2 className="main-head">Contact Us</h2>
                                </div>
                                <div className="row contct-outer">
                                    <div className="col-md-4 col-6">
                                        <div className="cntct-part">
                                            <h3 className='mt-0'>Email</h3>
                                            <a href="#ram@gmail.com">{emaicompany}</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="cntct-part">
                                            <h3 className='mt-0'>Contact</h3>
                                            <a href="">{mobile}</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-7">
                                        <div className="cntct-part">
                                            <h3 className='mt-0'>Address</h3>
                                            <a href="">{address}</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!----------------------------------------------------> */}
                                <div className="row for-map">
                                    <div className="col-lg-6">
                                        <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3423.275532100513!2d75.87892477558722!3d30.906921674500747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDU0JzI0LjkiTiA3NcKwNTInNTMuNCJF!5e0!3m2!1sen!2sin!4v1704952637507!5m2!1sen!2sin"
                                            height="500" style={{ border: 0, opacity: 0.7 }} allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                                        </iframe>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="contct-form">
                                            <h3>Get in touch. </h3>
                                            <form>
                                                <div className="mb-3">
                                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter email" onChange={(e) => setEmail(e)} />
                                                </div>
                                                <div className="mb-3">

                                                    <input type="tel" className="form-control" id="exampleInputPh" placeholder="Enter mobile number" />
                                                </div>
                                                <div className="mb-3">
                                                    <textarea className="form-control" id="floatingTextarea" placeholder="Message..." onChange={(e) => setDescription(e)}></textarea>
                                                </div>
                                                <button type="submit" className="sbt-btn" onChange={handleMessage}>Send Message</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    {/* <!-------------------------------------------------------> */}
                </div>
                {/* <!-----======================---Footer-------====================================--> */}
                <footer>
                    <div className="main-footer">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <h3>About company</h3>
                                <h5 ><p style={{ color: '#C0C0D5' }}>SHARP GRAPHS & GEARS is one of the established laser die maker founded in 1978 as a manual die maker and is
                                    located in
                                    Ludhiana City of Punjab.</p></h5>
                                <div className="footer-icons mt-3">
                                    <a href=""><img src={fb} /></a>
                                    <a href=""><img src={twitter} /></a>
                                    <a href=""><img src={linkedin} /></a>
                                    <a href=""><img src={whatsapp} /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6">
                                <div className="qck-lnks">
                                    <h3>Quick links</h3>
                                    <ul>
                                        <li>
                                            <a href="#about">About Us</a>
                                        </li>
                                        <li>
                                            <a href="#product">Our Product</a>
                                        </li>
                                        <li>
                                            <a href="#contact">Contact Us</a>
                                        </li>
                                        <li>
                                            <a href="#product">Our Product</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6">
                                <div className="gettouch">
                                    <h3>Get In touch</h3>
                                    <ul>
                                        <li><a href="mailto:Dieboard@sharpdies.com"> {emaicompany} </a></li>
                                        <li><a href="tel:1-514-343-0290">{mobile}</a></li>
                                        <li><a href="www.sharpdies.com">www.sharpdies.com</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <h3>Our Apps</h3>
                                <div className="plays-app">
                                    <a href=""><img src={googleplay} alt='...' /></a>
                                    <a href=""><img src={androidApp} alt='...' /></a>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="cpy-right d-flex">
                            <h6 className='m-0'>Copyright ©2023 Sharp dies. All Rights Reserved.</h6>
                            <div className="">
                                <a href="">Privacy Policy</a><span className="dot"></span>
                                <a href="">Terms & Condition</a>
                            </div>
                        </div>

                    </div>
                </footer>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
                    crossorigin="anonymous"></script>
            </main>
        </div >

    )
}
export default Landing;