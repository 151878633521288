import React from "react";
import logo from '../assets/images/LOGO 1.png';
import { Link } from 'react-router-dom';

function header() {
    return (
        <div className="container">
            <Link className="navbar-brand" href="#">
                <img src={logo} alt="logo not found" className="w-75" />
            </Link>
            <button className="navbar-toggler ms-auto border-0" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                <i className="ri-menu-3-fill fs-1"></i>
            </button>
            <div className="offcanvas offcanvas-end w-75" tabindex="-1" id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <img src={''} className="w-25" alt="" />
                    <button type="button" className="btn-close border-0 mx-2 fs-5" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div className="offcanvas-body text-center">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/" className="nav-link text-danger border-bottom border-danger px-3 fw-bold active"
                                aria-current="page">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-link text-black px-3 fw-bold">About</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to="/products" className="nav-link text-black px-3 fw-bold">Our Products</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/contactus" className="nav-link text-black px-3 fw-bold">Contact Us</Link>
                        </li>
                    </ul>
                    <div className="text-center">
                        <Link to="/register" className="btn btn-outline-danger mx-3 mb-lg-0 mb-3  px-4 fw-bold" type="submit">Let's Start</Link>
                        <Link to="/login" className="btn btn-danger mx-3 mb-lg-0 mb-3  px-4">Login</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default header;