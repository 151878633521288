import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assect/css/custom.css';
import { Link } from 'react-router-dom';

const TopMenu = () => {
    return (
        <div className="top_bar">
            <ul>
                <li><Link to="/standerd"><span className="stats_icon category_sl"></span><span className="label">Standard Library</span><span className="btn_intro">Standard Library is where u can check the products and buy. </span></Link></li>
                <li><Link to="/customorder"><span className="stats_icon folder_sl"></span><span className="label">New Order</span><span className="btn_intro">In Custom order you can upload design and proceed to buy. </span></Link></li>
                <li><Link to="/quation"><span className="stats_icon config_sl"></span><span className="label">Quotation</span><span className="btn_intro">In this tag you can make quotation and can convert it to order </span></Link></li>
                <li><Link to="/keyline"><span className="stats_icon finished_work_sl"></span><span className="label">Rule Change</span><span className="btn_intro"> Rule change is basicly for the repair of the old projects. </span></Link></li>
                <li><Link to="/consumables"><span className="stats_icon archives_sl"></span><span className="label">Consumables</span><span className="btn_intro"> In this you can order the raw material and consumables. </span></Link></li>
                <li><Link to="/paymenthistory"><span className="stats_icon bank_sl"></span><span className="label">Payment History</span><span className="btn_intro"> In this you can check the payment history and bill amount. </span></Link></li>
                <li><Link to="/myorders"><span className="stats_icon calendar_sl"></span><span className="label">My Order</span><span className="btn_intro"> In This you can check the status of your order and track them. </span></Link></li>
                <li><Link to="/usermanage"><span className="stats_icon user_sl"></span><span className="label">User Profile</span><span className="btn_intro">You Can Manage the profile and add sub users to oprate your account</span></Link></li>
            </ul>
        </div>
    )
}
export default TopMenu;