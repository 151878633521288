import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import { Component, useState } from "react";
import ReactPaginate from "react-paginate";
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
import Button from 'react-bootstrap/Button';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Modal } from "react-bootstrap";
import Loading from "../common/Loading";

const Sheetdata = () => {

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [loading, setLoading] = useState(false);

	const [currentPage, setCurrentPage] = useState(0);
	const itemsPerPage = 10;
	const [data, setData] = useState([]);
	const [search, setSearch] = useState('');


	// Calculate the total number of pages
	// const pageCount = Math.ceil(data.length / itemsPerPage);
	const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

	// Function to handle page change
	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};

	// Function to get the current page's data
	const getCurrentPageData = () => {
		// const startIndex = currentPage * itemsPerPage;
		// const endIndex = startIndex + itemsPerPage;
		// return data.slice(startIndex, endIndex);

		const startIndex = currentPage * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return data ? data.slice(startIndex, endIndex) : [];
	};

	const currentPageData = getCurrentPageData([]);

	useEffect(() => {
		handleOrder();
	}, []);

	const handleOrder = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);

		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.subiddisplay, formData);
			setData(response.data);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const [name, setName] = useState('');
	const [userid, setUserid] = useState('');
	const [password, setPassword] = useState('');

	const handleAddsubid = async () => {
		const token = localStorage.getItem('token');

		const formData = new FormData();
		formData.append('apikey', token);
		formData.append('name', name);
		formData.append('userid', userid);
		formData.append('pass', password);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.subidadd, formData);
			if (response.data.status === "success") {
				Swal.fire('Success', 'SubId Created Successfully!', 'success');
				setLoading(false);
			}
			else {
				Swal.fire({
					title: 'opps......',
					text: response.data.msg,
					icon: 'warning',
					showCancelButton: false,
					confirmButtonText: 'Confirm',
				});
				setLoading(false);
			}
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleSubIdDelete = async (id) => {
		const token = localStorage.getItem('token');

		const formData = new FormData();
		formData.append('apikey', token);
		formData.append('id', id);
		setLoading(true);

		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.subiddelete, formData);
			if (response.data.status === "success") {
				Swal.fire('Success', 'SubId Deleted Successfully!', 'success');
				setLoading(false);
			}
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			Swal.fire({
				title: 'opps......',
				text: 'Somthing Went Wrong',
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: 'Confirm',
			});
			setLoading(false);
		}
	}

	return (
		<div>
			<div id="theme-default" className="full_block">
				<div class="widget_wrap">
					<Loading loading={loading} />
					<div className="row justify-content-between">
						{/* <div className="col-lg-3 d-flex">
						<input type="text" class="form-control" placeholder="Type to search..."
							aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setSearch(e.target.value)} />
						<Button variant="success" style={{ margin: '10px' }} >Search</Button>
					</div> */}
						<div className="col-lg-3 d-flex">
							<Button variant="primary" onClick={handleShow}>Add Sub User</Button>
						</div>
					</div>

					<div class="widget_content">
						<table class="display data_tbl">
							<thead>
								<tr>
									<th>Name</th>
									<th>User id</th>
									<th>Password</th>
									<th>Delete</th>
								</tr>
							</thead>
							<tbody>
								{currentPageData && currentPageData.map((item) => (
									<tr>
										<td>{item.name}</td>
										<td>{item.userid}</td>
										<td>{item.pass}</td>
										<td><RiDeleteBin6Line size={24} onClick={() => handleSubIdDelete(item.autoid)} /></td>
									</tr>
								))}
							</tbody>
						</table>
						<hr />
						<ReactPaginate
							previousLabel={"Previous"}
							nextLabel={"Next"}
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={10}
							onPageChange={handlePageChange}
							containerClassName={"pagination"}
							subContainerClassName={"pages pagination"}
							activeClassName={"active"}
						/>

					</div>
					<Modal show={show} onHide={handleClose} centered size='lg'>
						<Modal.Header closeButton>
							<Modal.Title>Add Viewer</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className='row'>
								<div className='col-md-4 mt-2 '>
									<label className='pb-0'>Name</label>
									<input type="text" id="input-name" placeholder='Enter name' onChange={(e) => setName(e.target.value)} />
								</div>
								<div className='col-md-4 mt-2 '>
									<label className='pb-0'>User id</label>
									<input type="text" id="input-name" placeholder='Enter user id' onChange={(e) => setUserid(e.target.value)} />
								</div>
								<div className='col-md-4 mt-2 '>
									<label className='pb-0'>Password</label>
									<input type="text" id="input-name" placeholder='Enter password' onChange={(e) => setPassword(e.target.value)} />
								</div>
								<div className="col-md-2 mt-3"><Button variant="primary" onClick={handleAddsubid}>Create</Button></div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		</div>
	)
}
export default Sheetdata;