const lib = {
    apiURL: 'https://sharpgraphs.com/api',
    imgurl: "https://sharpgraphs.com/api/img",


    apis: {
        "signup": "auth/signup",
        "signfeth": "auth/signfeth",
        "login": "auth/userlogin",
        "header": "common/customerdata",
        "changepassword": "user/userprofile/changepass",
        "profile": "user/userprofile/profile",
        "updateprofile": "user/userprofile/updateprofile",
        "machinedata": "user/userprofile/machinedata",
        "walletbalance": "agent/walletbalance",
        "withdraw": "agent/withdrawpayment",
        "customorders": "user/custom_quation_order/mainorder",
        "paymenthistory": "user/payment/paymenthistory",
        "agentorderreport": "agent/orderreport",
        "WithdrawalReport": "agent/withdrawreport",
        "printmachinedata": "user/userprofile/printingmachinedata",
        "diemachine": "user/userprofile/diemachinedata",
        "totalorders":"user/dashboard/customergraph/totalorders",
        "totalsample":"user/dashboard/customergraph/totalsample",
        "totalquatation":"user/dashboard/customergraph/totalquatation",
        "totalkeyline":"user/dashboard/customergraph/totalkeyline",
        "consumable": "user/comsumble/consumbale",
        "standerds": "user/standerds/standerddisplay",
        "consumbalorder": "user/comsumble/consumbaleorder",
        "selecteddisplay": "user/selected/selecteddisplay",
        "treecat": "user/standerds/treecat",
        "treesubcat": "user/standerds/treesubcat",
        "sheetdata": "user/userprofile/entersheetdata",
        "sheetdatadisplay": "user/userprofile/sheetdata",
        "addfavourite": "user/standerds/saveselected",
        "sampleorder":"user/sample/sample",
        "keylines":"user/keyline/keylineorder",
        "rulechange":"user/rulechange/repairreplaceorder",
        "sampleordertable":"user/myorders/sampleorder",
        "keylinetable":"user/myorders/keylineorder",
        "consumabletable":"user/myorders/consumableorder",
        "rulechangeorder":"user/myorders/rulechangeorder",
        "customorderstable":"user/myorders/customquationorder",
        "messageindex":"common/messagelandingpage",
        "supportdetails":"user/support/supportdetails",
        "forgetpassword":"auth/forgetpass",
        "keymachinedata":"user/userprofile/machinetype",
        "keysheetdata":"user/userprofile/sheetdata",
        "standerdsorders":"user/standerds/standerdorder",
        "orderserch": "user/search/ordersearch",
        "standerdstable":"user/myorders/standerds",
        "chatbot":"user/support/chatbot",
        "subidadd":"user/subrole/subrole",
        "subiddisplay":"user/subrole/sudiddisplay",
        "subiddelete":"user/subrole/subiddelete",
        "standerddatadisplayfeth":"admin/job/standerddisplay",
        "quationjobdisplay":"admin/jobdisplay/quationjobdisplay",
        "rulechangedisplay":"admin/jobdisplay/rulechangedisplay",
        "consumablejobdisplay":"admin/jobdisplay/consumablejobdisplay",
        "keylinejobdisplay":"admin/jobdisplay/keylinejobdisplay",
        "customerdescription":"user/userprofile/descriptionadd",
        "standerdsaccessfeth":"user/standerds/standerdsaccess",
        "paymentrecordsubmit":"admin/paymenthistory/paymentrecordsubmit",
        "stockdisplayemploy":"employ/stock/stockdisplay",

        "deleteselected":"user/selected/deleteselected",
        "deletesheetdata":"user/userprofile/deletesheetdata",
        "deletemachinedata":"user/userprofile/deletemachinedata",
        "checkorderdetails":"user/standerds/checkorderdetails",
        "deletejob":"admin/jobdisplay/deletejob",
    }
};

export default lib;
