import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assect/css/custom.css';
import { Component, useState } from "react";
import ReactPaginate from "react-paginate";
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
import Button from 'react-bootstrap/Button';
import { RiDeleteBin6Line } from "react-icons/ri";
import Loading from "../common/Loading";

const PrintingMachine = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const itemsPerPage = 10;
	const [data, setData] = useState([]);
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		handleOrder();
	}, []);

	const handleOrder = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.printmachinedata, formData);
			setData(response.data);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleSearch = async () => {
		const token = localStorage.getItem('token');

		const formData = new FormData();
		formData.append('apikey', token);
		formData.append('type', 'machinedata');
		formData.append('search', search);
		setLoading(true);

		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.orderserch, formData);
			setData(response.data);
			setLoading(false);
		}
		catch (error) {
			console.log(error);
			setLoading(false);
		}
	}

	// Calculate the total number of pages
	// const pageCount = Math.ceil(data.length / itemsPerPage);
	const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

	// Function to handle page change
	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};

	// Function to get the current page's data
	const getCurrentPageData = () => {
		// const startIndex = currentPage * itemsPerPage;
		// const endIndex = startIndex + itemsPerPage;
		// return data.slice(startIndex, endIndex);

		const startIndex = currentPage * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return data ? data.slice(startIndex, endIndex) : [];
	};

	const currentPageData = getCurrentPageData([]);

	const handleDeleteMachine = async (id) => {
		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		formData.append('id', id);
		setLoading(true);

		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.deletemachinedata, formData);
			if (response.data.status === "success") {
				Swal.fire('Success', 'Deleted Successfully', 'success');
				handleOrder();
				setLoading(false);
			}
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	return (
		<div>
			<div id="theme-default" className="full_block">

				<div class="widget_wrap">
					<Loading loading={loading} />
					<div className="col-lg-3 d-flex">
						<input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setSearch(e.target.value)} />
						<Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
					</div>
					<div class="widget_content">
						<table class="display data_tbl">
							<thead>
								<tr>
									<th>Id</th>
									<th>Machine Name</th>
									<th>Max Print Sheet Area</th>
									<th>Max Sheet</th>
									<th>Mini Sheet</th>
									<th>Griper</th>
									<th>Select Measerments</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{currentPageData && currentPageData.map((item) => (
									<tr key={item.id}>
										<td>{item.id}</td>
										<td>{item.mn}</td>
										<td>{item.mpsa}</td>
										<td>{item.ms}</td>
										<td>{item.mis}</td>
										<td>{item.mss}</td>
										<td>{item.messerment}</td>
										<td><a href="#"><RiDeleteBin6Line size={22} color="black" onClick={() => handleDeleteMachine(item.id)} /></a></td>
									</tr>
								))}
							</tbody>
						</table>
						<hr />

						<ReactPaginate
							previousLabel={"Previous"}
							nextLabel={"Next"}
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={10}
							onPageChange={handlePageChange}
							containerClassName={"pagination"}
							subContainerClassName={"pages pagination"}
							activeClassName={"active"}
						/>

					</div>
				</div>
			</div>
		</div>
	)
}
export default PrintingMachine;