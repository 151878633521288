import React from "react";
import '../assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
// main bootstrap5 
import "bootstrap/dist/css/bootstrap.min.css";
import '../assets/css/bootstrap.min.css';
import '../assets/css/bootstrap.min.css.map';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../config/lib";
import { useNavigate } from "react-router-dom";
import LOGO from '../assets/images/LOGO.png'
import Loading from '../common/Loading'

function Login() {
    const navigate = useNavigate('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('email', email);
        formData.append('pass', password);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.login, formData);
            if (response.data.status === "success") {
                localStorage.setItem("token", response.data.apikey);
                localStorage.setItem("role", response.data.role);
                if (response.data.role === "user") {
                    navigate('/dashboard');
                    localStorage.setItem("subrole", response.data.subrole);
                    setLoading(false);
                }
                else {
                    if (response.data.role === "agent") {
                        navigate('/agentdashboard');
                        setLoading(false);
                    }
                    else {
                        navigate('/admindashboard');
                        setLoading(false);
                    }
                }
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'User id or password not mached or not approved by admin',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (

        <div className="login-bg">
            <div className="container login-height">
                <Loading loading={loading} />
                {/* <div className="col-sm-6 ps-0 mb-lg-0 mb-4">
                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="../assets/images/SportsCo.png" className="d-block w-100 loginslider1" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="../assets/images/SportsCo.png" className="d-block w-100 loginslider2" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-sm-6 mb-lg-0 mb-4">
                    <div className="mx-3 p-2">
                        <h3 className="login-text">Log in to your account!</h3>
                        <p>Let's start the journey</p>
                        <form>
                            <div className="mb-3">
                                <label for="exampleInputEmail1" className="form-label mb-1">Email-id</label>
                                <input type="email" className="form-control p-2" id="exampleInputEmail1" name="email" onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label for="exampleInputPassword1" className="form-label mb-1">Password</label>
                                <input type="password" className="form-control p-2" id="exampleInputPassword1" name="pass" onChange={(e) => setPassword(e.target.value)} required />
                            </div>
                            <div className="d-flex mb-3 align-items-center justify-content-between">

                                <div>
                                    <Link to="/ForgotPassword" className="text-decoration-none text-danger">Forgot Password?</Link>
                                </div>
                            </div>
                            <input type="submit" className="btn btn-danger w-100 mb-2" value="Login" name="login" onClick={handleLogin} />
                            <div className="d-flex mb-3 align-items-center">
                                <div style={{ flex: '1', height: '1px', backgroundColor: '#000' }}></div>
                                <div className="mx-4">
                                    OR
                                </div>
                                <div style={{ flex: '1', height: '1px', backgroundColor: '#000' }}></div>
                            </div>
                            <div className="text-center">
                                <Link to="/register" className="text-decoration-none"> New to G&G Laser?
                                    <span className="text-danger">
                                        Create an account.
                                    </span>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div> */}
                <div className="row justify-content-center ">
                    <div className="row justify-content-center mb-3 mt-3">
                        <div className="col-sm-2">
                            <img src={LOGO} alt="loginImage" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 shadow-sm p-5 pt-4 rounded-3 bg-white">
                        <h1 className="mb-4 text-center login-text">
                            Login
                        </h1>
                        <label for="exampleInputEmail1" className="form-label mb-1">Email-id</label>
                        <input type="email" className="form-control p-2" id="exampleInputEmail1" name="email" onChange={(e) => setEmail(e.target.value)} required placeholder="Enter email" />

                        <label for="exampleInputPassword1" className="form-label mb-1 mt-3">Password</label>
                        <input type="password" className="form-control p-2" id="exampleInputPassword1" name="pass" onChange={(e) => setPassword(e.target.value)} required placeholder="Enter password" />

                        <h5>
                            <Link to="/ForgotPassword" className="text-decoration-none second-color">Forgot Password?</Link>
                        </h5>

                        <input type="submit" className="btn custom-color w-100 mb-2 mt-3" value="Login" name="login" onClick={handleLogin} />

                        <h6 className="text-center">
                            <Link to="/register" className="text-decoration-none"> New to G&G Laser?
                                <span className="second-color ms-2">
                                    Create an account.
                                </span>
                            </Link>
                        </h6>
                    </div>
                </div>
            </div>

        </div>



    )
}
export default Login;
